import React from 'react';
import YouTube from 'react-youtube';

const portfolios_video_: React.FC = () => {
  const youtubeOpts = {
    width: '100%',
    height: '100%',
    playerVars: {
      autoplay: 1,
      playsinline: 1,
      loop: 1,
      playlist: '7SZqw8rtkZg',
      controls: 0,
      mute: 1,
    },
  };

  return (
    <>
      <section className="page-section portfolio_video" id="portfolio_video">
        <div className="container px-4 px-xxl-6 h-100">
          <div className="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-start ">
            <YouTube
              videoId="4NPGY6aGW8M"
              opts={youtubeOpts}
              className="video_player"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default portfolios_video_;
