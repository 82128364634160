import { Helmet } from 'react-helmet';
import Header from './components/soulx/header';
import { Route, Routes } from 'react-router-dom';
import './lib/styles/App.css';
import Main from 'pages/soulx/main';
import Service from 'pages/soulx/service';
import Portfolios from 'pages/soulx/portfolios';
import XroomCES from 'pages/soulx/XroomCES';
import About from 'pages/soulx/about';
import ScrollToTop from 'components/scrolltotop';
import RouteChangeTracker from 'components/routeChangeTracker';
import {
  ChakraProvider,
  createLocalStorageManager,
  ScaleFade,
} from '@chakra-ui/react';
import XROOMpage from './pages/XROOM/XROOMpage';
// import PageTemplate from 'components/base/PageTemplate';
import NotFoundPage from 'pages/NotFoundPage';
// import theme from '@chakra-ui/theme';
import { theme } from './lib/styles/chakraTheme';
import Core from 'containers/base/Core';
import Marketplacepage from 'pages/XROOM/marketplacepage';
import { JazzbarProvider } from 'lib/jazzbar';
import ConditionalBackground from 'components/base/ConditionalBackground';
import ErrorBoundary from 'components/error/ErrorBoundary';
import CESPage from 'pages/XROOM/CESPage';
import { useEffect } from 'react';
import PreDownload from 'pages/soulx/PreDownload';
import XroomSXSW from 'pages/soulx/XroomSXSW';
import XroomWIS from 'pages/soulx/XroomWIS';
import XroomEDISON from 'pages/soulx/XroomEDISON';
// import XroomEdison from 'pages/soulx/XroomEdison';
// const loadableConfig = {
//   fallback: <PageTemplate/>,
// };

const manager = createLocalStorageManager('my-key');

interface AppProps { }

const App: React.FC<AppProps> = (props: AppProps) => {
  return (
    // <ThemeProvider theme={muiTheme}>
    <ChakraProvider theme={theme} colorModeManager={manager}>
      <JazzbarProvider>
        <Helmet>
          <title>Soulx</title>
          <meta name="description" content="안녕하세요 소울 엑스 입니다." />
          <meta
            property="og:image"
            content="https://images.soulx.co.kr/soulx.png"
          />
        </Helmet>
        <ConditionalBackground />
        <RouteChangeTracker></RouteChangeTracker>
        <ScrollToTop></ScrollToTop>
        <ScaleFade in initialScale={0.95}>
          <Routes>
            {['/', '/about', '/service', '/portfolio'].map((path) => (
              <Route path={path} key={path} element={<Header />} />
            ))}
          </Routes>
          <ErrorBoundary>
            <Routes>
              {/* { ["/XROOM", "/XROOM/about"].map(path => <Route path={path} key={path} element={<XROOM_Header />} />) } */}
              <Route path="/" element={<Main />} />
              <Route path="/about" element={<About />} />
              <Route path="/service" element={<Service />} />
              <Route path="/portfolio" element={<Portfolios />} />
              <Route path="/xroom_ces" element={<XroomCES />} />
              <Route path="/xroom_sxsw" element={<XroomSXSW />} />
              <Route path="/xroom_wis" element={<XroomWIS />} />
              <Route path="/xroom_edison" element={<XroomEDISON />} />
              <Route path="/pre-download" element={<PreDownload />} />
              <Route path="/XROOM/*" element={<XroomSXSW />} />
              <Route path="/XROOM/launcher" element={<Marketplacepage />} />
              <Route path="/ces" element={<CESPage />} />
              {/* <Route path='/XROOM/*' element={XROOMpage(props)}/> */}
              <Route errorElement element={NotFoundPage(props)} />
            </Routes>
          </ErrorBoundary>
        </ScaleFade>
        <Core />
      </JazzbarProvider>
    </ChakraProvider>
    // </ThemeProvider>
  );
};

export default App;
