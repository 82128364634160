import React, { useEffect, useRef, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import Modal from '../../modal';
import emailjs from '@emailjs/browser';

const Contact: React.FC = () => {
  // Modal
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [loadingModalOpen, setLoadingModalOpen] = useState(false);

  const openContactModal = () => {
    setContactModalOpen(true);
  };
  const closeContactModal = () => {
    setContactModalOpen(false);
  };

  const openLoadingModal = () => {
    setLoadingModalOpen(true);
  };
  const closeLoadingModal = () => {
    setLoadingModalOpen(false);
  };

  // EmailJS
  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    openLoadingModal();
    emailjs
      .sendForm(
        'gmail',
        'template_i409945',
        e.currentTarget,
        'user_JhvymcoqMVHq5R5QVfxJ6',
      )
      .then(
        (result) => {
          alert('메일이 정상적으로 전송되었습니다.');
          closeLoadingModal();
          closeContactModal();
        },
        (error) => {
          alert('메일이 전송중 오류가 발생하였습니다.');
          closeLoadingModal();
          closeContactModal();
        },
      );
  };

  // Scroll
  const hashName = window.location.hash;
  const section = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (hashName === '#contact') {
      section.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [hashName]);

  return (
    <>
      <section className="page-section contact" id="contact" ref={section}>
        <Fade direction="left" className="h-100" triggerOnce>
          <div className="container px-4 px-lg-5" id="contactcontainer">
            <div className="row gx-4 gx-lg-5 justify-content-center">
              <div className="col-lg-8 col-xl-6 text-center">
                <div className="text-white fs-h-l-1-2 fs-h-s-2-2 fw-light mb-4-2 mt-h-6-1">
                  CONTACT
                </div>
                <div className="text-white fs-h-l-1-2 fs-h-s-2-1 fw-bolder mb-3">
                  서울시 강남구 역삼로 217 2층 1호 (역삼동,
                  뉴콘텐츠기업지원센터)
                </div>
                <div className="text-white fs-h-l-0-1 fs-h-s-1 fw-bolder mb-4-3">
                  2F-1, 217, Yeoksam-ro, Gangnam-gu, Seoul, Republic of Korea
                  06224
                </div>
                <div
                  className="text-white mb-4-3 btn btn-primary btn-l"
                  onClick={openContactModal}
                >
                  프로젝트 문의
                </div>
                <div className="text-white fs-h-l-1-2 fs-h-s-2-2 fw-bolder mb-4-3">
                  RECRUITMENT
                </div>
                <div className="text-white fs-h-l-1-2 fs-h-s-2-1 fw-bolder mb-4 word-keep">
                  포트폴리오와 이력서를 이메일로 보내주세요
                </div>
                <div className="text-white fs-h-l-0-1 fs-h-s-1 fw-bolder mb-3-2">
                  SEND US YOUR PORTFOLIO & RESUME
                </div>
                <div className="text-white fs-h-l-1 fs-h-s-2-1 fw-bolder mb-12">
                  master@soulx.co.kr
                </div>
                <a className="btn btn-primary btn-l" href="/#about">
                  맨위로
                </a>
              </div>
            </div>
          </div>
        </Fade>
      </section>

      <Modal open={contactModalOpen}>
        <div
          className="portfolio-modal"
          id="portfolioModal1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="close-modal" data-bs-dismiss="modal">
                <img
                  src={'/images/close-icon.svg'}
                  alt="Close modal"
                  onClick={closeContactModal}
                />
              </div>
              <div className="container">
                <div className="text-center">
                  <div className="fs-h-l-3 fs-h-s-2-3 text-white mt-h-l-1-1 mt-h-s-2">
                    프로젝트 문의
                  </div>
                </div>
                <form onSubmit={sendEmail} id="contactForm">
                  <div className="row align-items-stretch mb-h-l-2 mt-h-l-1-1 mt-h-s-2 mlr-h-l-1">
                    <div className="fs-h-l-1-1 fs-h-s-1 text-white text-start mb-h-l-1">
                      1. 간단한 정보를 입력해주세요
                    </div>
                    <div className="form-group mb-h-l-2 mt-h-s-1 col-lg-6">
                      <input
                        className="form-control"
                        name="company"
                        id="company"
                        type="text"
                        placeholder="기업명"
                        required
                      />
                    </div>
                    <div className="form-group mb-h-l-2 mt-h-s-1 col-lg-6">
                      <input
                        className="form-control"
                        name="name"
                        id="name"
                        type="text"
                        placeholder="담당자 / 직급"
                        required
                      />
                    </div>
                    <div className="form-group mb-h-l-2 mt-h-s-1 col-lg-6">
                      <input
                        className="form-control"
                        name="department"
                        id="department"
                        type="text"
                        placeholder="부서"
                        required
                      />
                    </div>
                    <div className="form-group mb-h-l-2 mt-h-s-1 col-lg-6">
                      <input
                        className="form-control"
                        name="phone"
                        id="phone"
                        type="tel"
                        placeholder="연락처"
                        required
                      />
                    </div>
                    <div className="form-group mt-h-s-1 col-lg-6">
                      <input
                        className="form-control"
                        name="email"
                        id="email"
                        type="email"
                        placeholder="이메일"
                        required
                      />
                    </div>
                    <div className="form-group mt-h-s-1 col-lg-6">
                      <input
                        className="form-control"
                        name="budget"
                        id="budget"
                        type="text"
                        placeholder="예산"
                        required
                      />
                    </div>
                    <div className="col-md-12 mt-h-l-1-1 mt-h-s-2">
                      <div className="fs-h-l-1-1 fs-h-s-1 text-white text-start mb-h-l-1 mb-h-s-2-1">
                        2. 프로젝트에 대한 정보 및 설명을 입력해주세요
                      </div>
                      <div className="form-group form-group-textarea mb-h-s-3-1">
                        <textarea
                          className="form-control"
                          name="message"
                          id="message"
                          placeholder="-  촬영 용도(발표/교육/홍보/라이브커머스 등):&#13;&#10;-  촬영 타입(녹화촬영 or 라이브송출):&#13;&#10;-  촬영 아이템(상세히 적어주실수록 좋습니다):&#13;&#10;-  촬영 예상 일자:&#13;&#10;-  촬영 참여 인원:&#13;&#10;-  기타 문의사항:"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <input
                      className="text-white mb-4-3  btn btn-primary btn-l"
                      name="submit"
                      id="submitButton"
                      type="submit"
                    ></input>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={loadingModalOpen}>
        <div
          className="modal_center"
          id="portfolioModal1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="container">
                <div className="text-center">
                  <div className="fs-h-l-3 fs-h-s-2-3 text-white">
                    이메일 전송중..
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Contact;
