import NaviIndex from 'components/naviIndex';
import VirtualStage from 'components/soulx/service/virtualstage';
import XRStudio from 'components/soulx/service/xrstudio';

const XR: React.FC = () => {
  return (
    <>
      <XRStudio></XRStudio>
      <VirtualStage></VirtualStage>
      <NaviIndex naviStrs={['xr_1', 'xr_2']}></NaviIndex>
    </>
  );
};

export default XR;
