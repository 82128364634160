import React, { useEffect, useRef } from 'react';
import { Fade } from 'react-awesome-reveal';
import clients from 'db/clients.json';
import Client from './client';

const Clients: React.FC = () => {
  const clientImageList: JSX.Element[] = clients.imagePaths.map((Name, idx) => (
    <Client imagePath={Name} key={idx}></Client>
  ));

  const hashName = window.location.hash;
  const section = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (hashName === '#clients') {
      section.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [hashName]);

  return (
    <>
      <section className="page-section clients" id="clients" ref={section}>
        <Fade direction="down" triggerOnce>
          <div className="container" id="clientscontainer">
            <div className="row px-xxl-5">{clientImageList}</div>
          </div>
        </Fade>
      </section>
    </>
  );
};

export default Clients;
