import '../../lib/styles/xroom_wis.css';
import XroomEdison from 'components/soulx/xroomCES/XroomEdison';

const XroomEDISON: React.FC = () => {
  return (
    <main className="xroom_wis">
      <XroomEdison />
    </main>
  );
};

export default XroomEDISON;
