import React from 'react';
type WorkProps = {
  imagePath: string;
  title: string;
  subtitle: string;
};

const Portfolio: React.FC<WorkProps> = ({ imagePath, title, subtitle }) => {
  return (
    <>
      <div className="col-lg-4 col-sm-6 mb-4">
        <div className="portfolio-item">
          <a
            className="portfolio-link"
            data-bs-toggle="modal"
            href="#portfolioModal3"
          >
            <div className="portfolio-hover">
              <div className="portfolio-hover-content">
                <i className="fas fa-plus fa-3x"></i>
              </div>
            </div>
            <img className="img-fluid mb-4-2" src={imagePath} alt="..." />
          </a>
          <div className="portfolio-caption">
            <div className="portfolio-caption-heading text-white fs-5-3 mb-2-1 fw-bold fw-bold">
              {title}
            </div>
            <div className="portfolio-caption-subheading text-white fs-8 fw-light">
              {subtitle}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfolio;
