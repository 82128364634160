import { useEffect, useRef } from 'react';
import { Box, Text, Button, useBreakpointValue, Img } from '@chakra-ui/react';

const Section = ({ bgColor, children, ref }: any) => {
  const padding = useBreakpointValue({
    base: '0 16px',
    sm: '0 16px ', // 480px
    md: '0 32px', // 768px
    xl: '0 320px', // 1280px
  });
  return (
    <Box bg={bgColor} p={padding} ref={ref}>
      {children}
    </Box>
  );
};

const TextArea = ({ title, content }: any) => {
  const TitleFontSize = useBreakpointValue({
    base: '28px',
    sm: '28px',
    md: '48px',
    xl: '54px',
  });

  const CtnFontSize = useBreakpointValue({
    base: '15px',
    sm: '15px',
    md: '20px',
    xl: '20px',
  });

  const layout = useBreakpointValue({
    base: 'block',
    sm: 'block',
    md: 'block',
    xl: 'flex',
  });

  const mb = useBreakpointValue({
    base: '20px',
    sm: '20px ',
    md: '28px',
    xl: '0px',
  });
  return (
    <Box display={layout} justifyContent="space-between">
      <Text
        fontSize={TitleFontSize}
        fontWeight="bold"
        width={useBreakpointValue({
          base: '100%',
          sm: '100%',
          md: '100%',
          xl: '500px',
        })}
      >
        {title}
      </Text>
      <Text
        fontSize={CtnFontSize}
        w={useBreakpointValue({
          base: '100',
          sm: '100%',
          md: '100%',
          xl: '720px',
        })}
        mt={mb}
        color={'#6B6C74'}
        lineHeight={useBreakpointValue({
          base: '22px',
          sm: '22px',
          md: '30px',
          xl: '28px',
        })}
      >
        {content}
      </Text>
    </Box>
  );
};

const XroomWISDesktop: React.FC = () => {
  const padding = useBreakpointValue({
    base: '0 16px',
    sm: '0 16px',
    md: '0 32px 195px',
    xl: '0 320px 132px',
  });
  const heightM = useBreakpointValue({
    base: '100vh',
    sm: '100vh',
    md: 'auto',
    xl: 'auto',
  });

  const fontSize = useBreakpointValue({
    base: '16px',
    sm: '16px',
    md: '20px',
    xl: '36px',
  });

  const fontPadding = useBreakpointValue({
    base: '12px 0 28px',
    sm: '12px 0 28px',
    md: '20px 0 32px',
    xl: '32px 0 39px',
  });

  const ImgHeight = useBreakpointValue({
    base: '27px',
    sm: '27px',
    md: '46px',
    xl: '57px',
  });

  const SectionPt = useBreakpointValue({
    base: '48px',
    sm: '48px',
    md: '90px',
    xl: '166px',
  });

  const SectionPt2 = useBreakpointValue({
    base: '0px',
    sm: '0px',
    md: '0px',
    xl: '180px',
  });

  const firstSectionRef = useRef<HTMLDivElement>(null);
  const secondSectionRef = useRef<HTMLDivElement>(null);
  const thridSectionRef = useRef<HTMLDivElement>(null);
  const fourthSectionRef = useRef<HTMLDivElement>(null);
  const fifthSectionRef = useRef<HTMLDivElement>(null);
  const sixthSectionRef = useRef<HTMLDivElement>(null);
  const seventhSectionRef = useRef<HTMLDivElement>(null);
  const eighthSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (firstSectionRef.current) {
      const observer = new IntersectionObserver((elements) => {
        elements.forEach((el) =>
          el.target.animate([{ opacity: 0 }, { opacity: 1 }], {
            duration: 800,
            delay: 300,
            easing: 'ease-out',
            fill: 'both',
          }),
        );
      });

      if (firstSectionRef.current) {
        observer.observe(firstSectionRef.current);
      }
      if (secondSectionRef.current) {
        observer.observe(secondSectionRef.current);
      }
      if (thridSectionRef.current) {
        observer.observe(thridSectionRef.current);
      }
      if (fourthSectionRef.current) {
        observer.observe(fourthSectionRef.current);
      }
      if (fifthSectionRef.current) {
        observer.observe(fifthSectionRef.current);
      }
      if (sixthSectionRef.current) {
        observer.observe(sixthSectionRef.current);
      }
      if (seventhSectionRef.current) {
        observer.observe(seventhSectionRef.current);
      }
      if (eighthSectionRef.current) {
        observer.observe(eighthSectionRef.current);
      }
    }
  }, [firstSectionRef]);
  return (
    <>
      <Box display="flex" flexDirection="column">
        {/* 메인 */}
        <Box
          ref={firstSectionRef}
          p={padding}
          backgroundImage="url('images/xroom_wis/mainBg.png')"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          height={heightM}
          display={'flex'}
          alignItems={useBreakpointValue({
            base: 'center',
            sm: 'center',
            md: 'center',
            xl: 'flex-start',
          })}
          justifyContent={'center'}
          flexDirection={'column'}
        >
          <Img
            src="/images/xroom_wis/logo.png"
            alt="xroom_logo"
            // width={203}
            height={ImgHeight}
            marginTop={useBreakpointValue({
              base: 'none',
              sm: 'none',
              md: '114px',
              xl: '114px',
            })}
          />
          <Text fontSize={fontSize} p={fontPadding}>
            XROOM과 함께 3D 스토리텔링의 모든 것을 경험하세요!
          </Text>
          <video controls muted loop playsInline width="100%" autoPlay>
            <source
              src="https://asset.soulx.co.kr/soulx-homepage/2024_CES_video3.mp4"
              type="video/mp4"
            />
          </video>
        </Box>
        <Box
          ref={secondSectionRef}
          p={useBreakpointValue({
            base: '0 16px',
            sm: '0 16px',
            md: '0 32px',
            xl: '0 320px',
          })}
        >
          <Box pt={SectionPt}>
            <TextArea
              title={useBreakpointValue({
                base: (
                  <>
                    가상공간을 실시간으로
                    <br />
                    보여주는 마법
                  </>
                ),
                xl: <>가상공간을 실시간으로 보여주는 마법</>,
                default: '가상공간을 실시간으로 보여주는 마법',
              })}
              content={
                <>
                  게임 엔진을 활용한 고품질 실시간 랜더링으로 완벽한 몰입을
                  경험하세요! 우리의 기술은 현실과 구분할 수 없는 그래픽을
                  제공하여 당신을 더욱 몰입시킵니다
                </>
              }
            />
            <Img
              src="/images/xroom_wis/rectangle.jpg"
              alt="rectangle"
              marginTop={useBreakpointValue({
                base: '32px',
                sm: '32px',
                md: '52px',
                xl: '48px',
              })}
            />
          </Box>
        </Box>
        <Box
          ref={thridSectionRef}
          p={useBreakpointValue({
            base: '0 16px',
            sm: '0 16px',
            md: '0 32px',
            xl: '0 320px',
          })}
        >
          <Box
            mt={useBreakpointValue({
              base: '120px',
              sm: '120px',
              md: '200px',
              xl: '240px',
            })}
          >
            <TextArea
              title={useBreakpointValue({
                base: (
                  <>
                    공간 기반 스토리텔링을
                    <br />
                    활용한 영상제작
                  </>
                ),
                xl: <>공간 기반 스토리텔링을 활용한 영상제작</>,
                default: '공간 기반 스토리텔링을 활용한 영상제작',
              })}
              content={
                <>
                  XROOM은 사용하기 쉬운 UI와 자유로운 카메라 연출로 편리함을
                  제공합니다. 간편한 튜토리얼로 누구나 나만의 이야기를 전달할 수
                  있으며, 실시간 협업과 콘텐츠 공유가 가능합니다. XR 프로덕션을
                  촉진하며 콘텐츠 제작의 다양한 기회를 제공합니다.
                </>
              }
            />
            <Img
              src={useBreakpointValue({
                base: '/images/xroom_wis/mobile1.png',
                sm: '/images/xroom_wis/mobile1.png',
                md: '/images/xroom_wis/groupImg.png',
                xl: '/images/xroom_wis/groupImg.png',
              })}
              alt="groupImg"
              marginTop={useBreakpointValue({
                base: '32px',
                sm: '32px',
                md: '52px',
                xl: '48px',
              })}
              marginBottom={useBreakpointValue({
                base: '120px',
                sm: '120px',
                md: '200px',
                xl: '240px',
              })}
            />
          </Box>
        </Box>
        <Box
          bg={useBreakpointValue({
            base: 'none',
            sm: 'none',
            md: 'none',
            xl: 'linear-gradient(to right bottom,rgba(238,244,255,1) 0%, rgba(233,255,247,1) 50%, rgba(249,237,255,1) 100%)',
          })}
          ref={fourthSectionRef}
          p={useBreakpointValue({
            base: '0 16px',
            sm: '0 16px ',
            md: '0 32px',
            // lg: '62em',
            xl: '0 320px',
          })}
        >
          <Box pt={SectionPt2}>
            <TextArea
              title={<>AI를 활용한 이미지생성</>}
              content={
                <>
                  XROOM은 이미지 생성 AI를 자체적으로 내장하여 사용자들에게
                  창의적인 활용을 제공합니다. 사용자는 이 기능을 통해 XROOM
                  내에서 AI를 활용하여 창의적인 프로젝트를 시작하고 더 나은 협업
                  환경을 구축할 수 있습니다.
                </>
              }
            />
            <Img
              src={useBreakpointValue({
                base: '/images/xroom_wis/mobile2.png',
                sm: '/images/xroom_wis/mobile2.png',
                md: '/images/xroom_wis/aiImg.jpg',
                xl: '/images/xroom_wis/aiImg.jpg',
              })}
              alt="rectangle"
              // width={203}
              // height={VideoHeight}
              marginTop={useBreakpointValue({
                base: '32px',
                sm: '32px',
                md: '52px',
                xl: '48px',
              })}
              marginBottom={useBreakpointValue({
                base: '120px',
                sm: '120px',
                md: '200px',
                xl: '240px',
              })}
            />
          </Box>
        </Box>
        <Box
          ref={fifthSectionRef}
          p={useBreakpointValue({
            base: '0 16px',
            sm: '0 16px',
            md: '0 32px',
            xl: '0 320px',
          })}
        >
          <Box
            p={useBreakpointValue({
              base: '0 0px 120px 0px',
              sm: '0 0px 120px 0px',
              md: '0 0px 200px 0px',
              xl: '170px 0 240px',
            })}
          >
            <TextArea
              title={<>방송을 위한 가상 아바타</>}
              content={
                <>
                  학생들은 다채로운 3D 에셋과 이펙트를 사용해 자신만의
                  프로젝트를 만들고, 창의력과 상상력을 확장시킬 수 있습니다.
                  마음껏 공간을 편집하고 새로운 콘텐츠를 만들면서 자신만의 공간
                  브랜딩을 시작할 수 있습니다.
                </>
              }
            />
            <Img
              src={useBreakpointValue({
                base: '/images/xroom_wis/mobile3.png',
                sm: '/images/xroom_wis/mobile3.png',
                md: '/images/xroom_wis/groupImg2.png',
                xl: '/images/xroom_wis/groupImg2.png',
              })}
              alt="groupImg"
              marginTop={useBreakpointValue({
                base: '32px',
                sm: '32px',
                md: '52px',
                xl: '48px',
              })}
            />
          </Box>
        </Box>
        <Box ref={sixthSectionRef}>
          <Section>
            <TextArea
              title={useBreakpointValue({
                base: (
                  <>
                    장소에 구애없이
                    <br />
                    간단한것 부터 전문적 영역까지
                  </>
                ),
                xl: <>장소에 구애없이 간단한 것 부터 전문적 영역까지</>,
                default: '장소에 구애없이 간단한것 부터 전문적 영역까지',
              })}
              content={
                <>
                  집에서 부터 대형 촬영장소에서까지 어디에서든 촬영이
                  가능합니다. 또한 버츄얼 스튜디오와 360도 촬영장소, 다목적실을
                  제공하는 자사 소울엑스가 XROOM과 함께합니다.
                </>
              }
            />
          </Section>
          <Box
            p={useBreakpointValue({
              base: '0 16px',
              sm: '0 16px',
              md: '0 32px',
              xl: '0',
            })}
          >
            <Img
              src={useBreakpointValue({
                base: '/images/xroom_wis/mobileFrameImg.png',
                sm: '/images/xroom_wis/mobileFrameImg.png',
                md: '/images/xroom_wis/mobileFrameImg.png',
                xl: '/images/xroom_wis/frame.png',
              })}
              alt="frame"
              // width={203}
              // height={VideoHeight}
              marginTop={useBreakpointValue({
                base: '32px',
                sm: '32px',
                md: '52px',
                xl: '48px',
              })}
            />
          </Box>
        </Box>
        {/* section7 */}
        <Box
          ref={seventhSectionRef}
          p={useBreakpointValue({
            base: '120px 0',
            sm: '120px 0',
            md: '200px 32px',
            xl: '280px 320px 190px',
          })}
          textAlign={useBreakpointValue({
            base: 'left',
            sm: 'left',
            md: 'left',
            xl: 'center',
          })}
        >
          <Box
            p={useBreakpointValue({
              base: '0 16px',
              sm: '0 16px',
              md: '0',
              xl: '0',
            })}
          >
            <Text
              fontSize={useBreakpointValue({
                base: '24px',
                sm: '24px ',
                md: '48px',
                xl: '48px',
              })}
              fontWeight="bold"
            >
              모두를 위한 생생한 XR 제작 경험
            </Text>
            <Text
              fontSize={useBreakpointValue({
                base: '15px',
                sm: '15px ',
                md: '20px',
                xl: '20px',
              })}
              pt={'20px'}
              color={'#6B6C74'}
              fontWeight={'medium'}
              lineHeight={'20px'}
            >
              XROOM이 제공하는 세 가지 플랜
            </Text>
            <Button
              backgroundColor="black"
              color="white"
              _hover={{ backgroundColor: 'gray.800' }} // hover 시 색 변경
              _active={{ backgroundColor: 'gray.900' }} // 클릭 시 색 변경
              p={'9px 22px'}
              borderRadius={'4px'}
              mt={'26px'}
              onClick={() => {
                window.open(
                  'https://docs.google.com/forms/d/e/1FAIpQLSf4_ZLPFDwXL0ZgCCKaF3WopR29o4d7VEh79eflk30NqKJ6ag/viewform?usp=sf_link',
                );
              }}
              fontWeight={'medium'}
            >
              가격 문의하기
            </Button>
          </Box>
          <Box
            p={useBreakpointValue({
              base: '0 16px',
              sm: '0 16px',
              md: '0',
              xl: '0',
            })}
          >
            <Text
              fontSize={'12px'}
              pt={'32px'}
              color={'#83838C'}
              textAlign={'left'}
              display={useBreakpointValue({
                base: 'block',
                sm: 'block',
                md: 'none',
                xl: 'none',
              })}
            >
              *XROOM은 1회 구매시 3년간 유지되는 시스템입니다.
            </Text>
            <Box
              overflowX={'scroll'}
              css={{
                '&::-webkit-scrollbar': {
                  display: 'none',
                  scrollbarWidth: 'none',
                },
                scrollbarWidth: 'none',
              }}
            >
              <Img
                src={useBreakpointValue({
                  base: '/images/xroom_wis/mobileList.png',
                  sm: '/images/xroom_wis/mobileList.png',
                  md: '/images/xroom_wis/desktopList.png',
                  xl: '/images/xroom_wis/desktopList.png',
                })}
                alt="frame"
                marginTop={useBreakpointValue({
                  base: '20px',
                  sm: '20px',
                  md: '40px',
                  xl: '60px',
                })}
                maxWidth={useBreakpointValue({
                  base: '155%',
                  sm: '155%',
                  md: '100%',
                  xl: '100%',
                })}
              />
            </Box>
            <Text
              fontSize={'16px'}
              pt={'20px'}
              color={'#83838C'}
              textAlign={useBreakpointValue({
                base: 'left',
                sm: 'left',
                md: 'right',
                xl: 'right',
              })}
              display={useBreakpointValue({
                base: 'none',
                sm: 'none',
                md: 'block',
                xl: 'block',
              })}
            >
              *XROOM은 1회 구매시 3년간 유지되는 시스템입니다.
            </Text>
          </Box>
        </Box>

        <Box
          ref={eighthSectionRef}
          backgroundImage="url('/images/xroom_wis/footerBg.png')"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          height={'100vh'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          p={useBreakpointValue({
            base: '0 22px',
            sm: '0 22px',
            md: '0 32px',
            xl: '0',
          })}
        >
          <Img
            src="/images/xroom_wis/logo.png"
            alt="frame"
            width={useBreakpointValue({
              base: '162px',
              sm: '162px',
              md: '335px',
              xl: '404px',
            })}
          />
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <Text
              fontSize={useBreakpointValue({
                base: '20px',
                sm: '20px',
                md: '48px',
                xl: '56px',
              })}
              fontWeight="medium"
              mt={useBreakpointValue({
                base: '20px',
                sm: '20px',
                md: '40px',
                xl: '40px',
              })}
            >
              XROOM을 탐험할 준비가 되셨나요?
            </Text>
            <Text
              mt={useBreakpointValue({
                base: '8px',
                sm: '8px',
                md: '20px',
                xl: '20px',
              })}
              fontSize={useBreakpointValue({
                base: '16px',
                sm: '16px',
                md: '20px',
                xl: '20px',
              })}
              textAlign={'center'}
            >
              XR 콘텐츠 제작으로 나만의 세상을 더 생생하게 만드세요. XR 프로덕션
              솔루션으로 뉴미디어 시대의 리더를 양성합니다.
            </Text>
            <Button
              background={'#000'}
              size="md"
              borderRadius="4px"
              color={'#fff'}
              fontWeight="medium"
              fontSize={'20px'}
              mt={useBreakpointValue({
                base: '16px',
                sm: '16px',
                md: '20px',
                xl: '20px',
              })}
              _hover={{ background: '#444' }}
              onClick={() => {
                window.open(
                  ' https://store.steampowered.com/app/2616930/XROOMLite/',
                );
              }}
            >
              Download the XROOM
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default XroomWISDesktop;
