import ScrollSpy from 'react-scrollspy';

type NaviProps = {
  naviStrs: string[];
};

const NaviIndex: React.FC<NaviProps> = ({ naviStrs }) => {
  const naviStrList: JSX.Element[] = naviStrs.map((item, idx) => (
    <li className="navi_button nav-item" key={idx}>
      <a className="navi_a_button" href={'#' + item}></a>
    </li>
  ));

  return (
    <>
      <nav
        className="fixed-right d-flex align-items-center subnavbar navbar-expand-lg navbar-light"
        id="subnav"
      >
        <button
          className="navbar-toggler navbar-toggler-right"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive-2">
          <ScrollSpy
            className="XROOM_ul"
            items={naviStrs}
            currentClassName="navi_current"
          >
            {naviStrList}
          </ScrollSpy>
        </div>
      </nav>
    </>
  );
};

export default NaviIndex;
