import { Box, Button, Flex, Img, Stack } from '@chakra-ui/react';
import FeatureBox from './common/FeatureBox';
import MainVisual from './common/MainVisual';
import Video from './common/Video';
import { ReactComponent as FeatureBoxDesktop1 } from '../../../static/xroom_ces/featurebox__desktop.svg';
import { ReactComponent as FeatureBoxDesktop2 } from '../../../static/xroom_ces/featurebox2__desktop.svg';
import ImgCard from './common/ImgCard';
import ServiceItemCard from './common/ServiceItemCard';
import HorizontalCard from './common/HorizontalCard';

const XroomCESDesktop: React.FC = () => {
  return (
    <Box className="xroom_ces__container">
      <MainVisual />
      <Video width="1280px" height="720px" />
      <FeatureBox
        className="xr"
        FeatureBoxDesktop={FeatureBoxDesktop1}
        height={1034}
      >
        <FeatureBox.Infos sub="Amazing XR experience" color="#F1DEFF">
          <FeatureBox.Title>
            "An easy and accessible XR production for everyone."
          </FeatureBox.Title>
          <FeatureBox.Details>
            XROOM is a revolutionary XR production solution that makes magical
            storytelling possible for anyone.
            <br /> Amateur creators, even teenagers, can create high-quality
            video content as easily as playing a game, with just a few hours of
            learning.
          </FeatureBox.Details>
        </FeatureBox.Infos>
        <FeatureBox.Contents>
          <ImgCard
            imgName="feature1_img1.png"
            desc="Infinite 3D objects & environments"
            isHorizontal
          />
          <ImgCard
            imgName="feature1_img2.png"
            desc="Real-time creation and sharing"
            isHorizontal
          />
          <ImgCard
            imgName="feature1_img3.png"
            desc="AI-powered spatial branding"
            isHorizontal
          />
          <ImgCard
            imgName="feature1_img4.png"
            desc="Cinematic effect with a virtual camera"
            isHorizontal
          />
        </FeatureBox.Contents>
      </FeatureBox>
      <section className="service_item">
        <Flex gap="112px">
          <ServiceItemCard
            width="620px"
            imgName="xroom_pro"
            title="XROOM Pro"
            sub="XR Production Education"
            desc="XR Content Creation Tool and Educational Curriculum To develop Future Creators and Media Professionals"
          >
            <div
              style={{
                background: 'linear-gradient(135deg, #8E2DE2 0%, #4A00E0 100%)',
                width: '142px',
                padding: '10px 20px',
                color: '#fff',
                textAlign: 'center',
                fontWeight: 400,
                cursor: 'pointer',
              }}
              onClick={() => {
                window.open(
                  'https://store.steampowered.com/app/2616930/XROOMLite/',
                );
              }}
            >
              TRY DEMO
            </div>
          </ServiceItemCard>
          <ServiceItemCard
            width="548px"
            imgName="xroom_online"
            title="XAM"
            sub="Short-form XR Production"
            desc="XR-based Video Creation Tool For Youtube
            Shorts, Instagram Reels, and TikTok content"
          >
            <div
              style={{
                background: 'linear-gradient(135deg, #8E2DE2 0%, #4A00E0 100%)',
                width: '142px',
                padding: '10px 20px',
                color: '#fff',
                textAlign: 'center',
                fontWeight: 400,
                cursor: 'pointer',
              }}
              onClick={() => {
                window.open('https://xam.soulx.co.kr/');
              }}
            >
              TRY NOW
            </div>
          </ServiceItemCard>
        </Flex>
      </section>
      <FeatureBox
        className="assets"
        height={831}
        FeatureBoxDesktop={FeatureBoxDesktop2}
      >
        <FeatureBox.Infos sub="3D Objects & Effects for Dynamic Storytelling!">
          <FeatureBox.Title>
            Infinite 3D assets for any purpose
          </FeatureBox.Title>
          <FeatureBox.Details>
            Expand your creativity and imagination with a variety of 3D assets
            and effects to create original content. <br />
            Freely organize and edit spaces in real time to tell your story
            effectively, with any purpose, whether it's to educate, advertise,
            or perform.
          </FeatureBox.Details>
        </FeatureBox.Infos>
        <FeatureBox.Contents>
          <ImgCard isHorizontal={false} imgName="feature2_img1.png" />
          <ImgCard imgName="feature2_img2.png" />
          <ImgCard imgName="feature2_img3.png" />
        </FeatureBox.Contents>
      </FeatureBox>
      <section className="contents">
        <Stack width={1482} gap={'190px'}>
          <HorizontalCard className="livestream">
            <HorizontalCard.Img imgName="content_livestream" />
            <HorizontalCard.Desc
              sub="Live streaming made easy, anytime"
              width="555px"
            >
              <HorizontalCard.Title>
                Share your content live at the touch of a button
              </HorizontalCard.Title>
              <HorizontalCard.Details>
                XRoom works with video streaming platforms like YouTube to make
                it easy to share your screen in real time. Enjoy efficient live
                streaming without complicated setup.
              </HorizontalCard.Details>
            </HorizontalCard.Desc>
          </HorizontalCard>
          <HorizontalCard className="interface">
            <HorizontalCard.Desc
              sub="XR and AI technology, what's next…?"
              width="549px"
            >
              <HorizontalCard.Title>
                A flexible and scalable tool with endless possibilities!
              </HorizontalCard.Title>
              <HorizontalCard.Details>
                XROOM is a highly scalable creation tool that enables rapid
                adoption of the latest technologies. It applies newly updated
                technologies in the most accessible form, making it intuitive
                for anyone to utilize them.
              </HorizontalCard.Details>
            </HorizontalCard.Desc>
            <HorizontalCard.Img imgName="content_interface" />
          </HorizontalCard>
        </Stack>
      </section>
      <section className="last--visual ">
        <Flex
          className="last--visual__container"
          direction="column"
          gap={'30px'}
          align="center"
          height={479}
        >
          <Img
            src="/images/xroom_ces/xroom_last.png"
            alt="xroom logo"
            width={455}
            height={89}
            marginTop={108}
          />
          <Stack width={1137} align="center" gap={'60px'}>
            <Stack align="center" gap={'20px'}>
              <h1>Are you ready to explore XROOM?</h1>
              <p>
                Bring your own world to life with XR content creation. Become
                the freest creator of the Metaverse era.
              </p>
            </Stack>
            <Stack width={168} align="center" gap={'20px'}>
              <div
                style={{
                  background:
                    'linear-gradient(135deg, #8E2DE2 0%, #4A00E0 100%)',
                  width: '168px',
                  padding: '10px 20px',
                  color: '#fff',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  window.open(
                    'https://docs.google.com/forms/d/e/1FAIpQLSeGqhJwxOVZaxUx4-iH0LbGmNTpqHCer8-4ZhWuJlQypdg9qw/viewform',
                  );
                }}
              >
                CONTACT US
              </div>
            </Stack>
          </Stack>
        </Flex>
      </section>
    </Box>
  );
};

export default XroomCESDesktop;
