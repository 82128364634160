import React from 'react';
import { Fade } from 'react-awesome-reveal';

const History: React.FC = () => {
  return (
    <>
      <section className="history" id="history">
        <div className="container px-xxl-6 text-start">
          <div className="row gx-5 align-items-end justify-content-center">
            <Fade direction="left" triggerOnce>
              <div className="row gx-5 align-items-end justify-content-center">
                <div className="col-12">
                  <div className="text-white fw-bolder mb-h-s-4 history-title">
                    연혁
                  </div>
                </div>
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mt-h-l-2 mb-h-s-3 history-year">
                    2019
                  </div>
                </div>
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mb-h-s-2 history-month">
                    3월
                  </div>
                </div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-1 history-content">
                    법인설립
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-2 history-content">
                    (본사 : 서울시 양천구 목동동로 233-1 1819호 목동
                    현대드림타워)
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mb-h-s-2 history-month">
                    4월
                  </div>
                </div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-1 history-content">
                    상호업무협력협약 (동아닷컴평생교육아카데미)
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-1 history-content">
                    게임제작업 등록
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-2 history-content">
                    통신판매업 등록
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mb-h-s-2 history-month">
                    6월
                  </div>
                </div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-2 history-content">
                    상표등록
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mb-h-s-2 history-month">
                    11월
                  </div>
                </div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-4 history-content">
                    중소기업 R&D 기획지원 멘토링 사업 협약
                  </div>
                </div>
              </div>
            </Fade>

            <Fade direction="left" triggerOnce>
              <div className="row gx-5 align-items-end justify-content-center">
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mt-h-l-2 mb-h-s-3 history-year">
                    2020
                  </div>
                </div>
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mb-h-s-2 history-month">
                    3월
                  </div>
                </div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-1 history-content">
                    창업기업지원서비스 바우처 창업기업 선정
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-2 history-content">
                    중소벤처기업부 창업성공패키지 선정, 청년창업사관학교 입교
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mb-h-s-2 history-month">
                    6월
                  </div>
                </div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-2 history-content">
                    기술특허출원
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mb-h-s-2 history-month">
                    9월
                  </div>
                </div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-1 history-content">
                    서울시 지역 수요맞춤 R&D 사업 선정
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-2 history-content">
                    경기콘텐츠진흥원 기업육성프로그램 코칭 멘토
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mb-h-s-2 history-month">
                    10월
                  </div>
                </div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-2 history-content">
                    오픈놀, 사단법인 코네 업무제휴 협약
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mb-h-s-2 history-month">
                    11월
                  </div>
                </div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-1 history-content">
                    안양 청년창업 데모데이 4위 선정
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-2 history-content">
                    글로벌 특화 프로젝트 스타트업X스타트업 1위 우승
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mb-h-s-2 history-month">
                    12월
                  </div>
                </div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-1 history-content">
                    비대면바우처수요기업 선정
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-1 history-content">
                    2020년도 창업성장기술개발 디딤돌 창업과제 선정
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-1 history-content word-keep">
                    기술특허등록 (등록번호 : 제10-2192412호 / 3D 가상공간에
                    실시간 가상영상을 합성하는 방법 및 그룹 이용한 컴퓨터 장치
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-4 mb-h-s-2 history-content">
                    산학협력협약 (SBS아카데미 게임학원)
                  </div>
                </div>
              </div>
            </Fade>

            <Fade direction="left" triggerOnce>
              <div className="row gx-5 align-items-end justify-content-center">
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mt-h-l-2 mb-h-s-3 history-year">
                    2021
                  </div>
                </div>
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mb-h-s-2 history-month">
                    1월
                  </div>
                </div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-1 history-content">
                    디지털 신기술 핵심 실무인제 양성훈련
                    협약(영우글로벌러닝평생교육원)
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-2 history-content">
                    벤처기업인증
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mb-h-s-2 history-month">
                    3월
                  </div>
                </div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-1 history-content">
                    창업기업지원서비스 바우처 창업기업 선정
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-1 history-content">
                    2021 창업성공패키지 (청년창업사관학교)창업코디네이터 임명
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-2 history-content">
                    업무협력협약 (프라임씨)
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mb-h-s-2 history-month">
                    4월
                  </div>
                </div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-2 history-content">
                    클라우드 기반 SW개발환경 지원사업 선정
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mb-h-s-2 history-month">
                    6월
                  </div>
                </div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-1 history-content">
                    소상공인 디지털 커머스 운영기관 공모 선정 (컨소시엄)
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-2 history-content">
                    영등포청년창업생태계조성사업 - MVP 엑셀러레이팅 프로그램
                    선정
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mb-h-s-2 history-month">
                    7월
                  </div>
                </div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-2 history-content word-keep">
                    영우글로벌러닝 / 한국소프트웨어산업협회 K - digital Training
                    벤처 스타트업 아카데미 협약 체결
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mb-h-s-2 history-month">
                    8월
                  </div>
                </div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-1 history-content">
                    SBA 기술상용화 지원사업 선정
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 history-content">
                    2021 CKL 경남 콘텐츠 창업 아이디어 헤커톤
                    멘토(경남코리아콘텐츠랩)
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mb-h-s-2 history-month">
                    9월
                  </div>
                </div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-1 history-content">
                    2021년 스타트업 지식재산바우처 사업 선정
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 history-content">
                    2021년 업종전환·재창업 사업화 지원 「E-커머스 인큐베이팅
                    사업」선정
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mb-h-s-2 history-month">
                    11월
                  </div>
                </div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-1 history-content">
                    2021년 청창사 졸업기업 후속연계지원
                  </div>
                </div>
              </div>
            </Fade>

            <Fade direction="left" triggerOnce>
              <div className="row gx-5 align-items-end justify-content-center">
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mt-h-l-2 mb-h-s-3 history-year">
                    2022
                  </div>
                </div>
                <div className="col-lg-1-1">
                  <div className="text-white fw-bolder mb-h-s-2 history-month">
                    3월
                  </div>
                </div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-1 history-content">
                    2022년도 창업성장기술개발사업 전략형 선정
                  </div>
                </div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-1-1"></div>
                <div className="col-lg-8-1">
                  <div className="text-white mt-h-l-1 mb-h-s-2 history-content">
                    퍼스트펭귄형 창업기업 선정(신용보증기금)
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </>
  );
};

export default History;
