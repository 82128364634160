import NaviIndex from 'components/naviIndex';
import Portfolio_01 from 'components/soulx/portfolio/portfolio_01';
import Portfolio_02 from 'components/soulx/portfolio/portfolio_02';
import Portfolio_video from 'components/soulx/portfolio/portfolio_video';
import Clients from 'components/soulx/portfolio/clients';

const Portfolios: React.FC = () => {
  return (
    <>
      <Portfolio_01></Portfolio_01>
      <Portfolio_02></Portfolio_02>
      <Portfolio_video></Portfolio_video>
      <Clients></Clients>
      <NaviIndex
        naviStrs={[
          'portfolio_01',
          'portfolio_02',
          'portfolio_video',
          'clients',
        ]}
      ></NaviIndex>
    </>
  );
};

export default Portfolios;
