import { Button, Flex, Img, Stack } from '@chakra-ui/react';

const MainVisual: React.FC = () => {
  return (
    <section className="main--visual">
      <Flex
        className="main--visual__container"
        height={474}
        direction="column"
        gap={'20px'}
        align="center"
        marginTop={225}
        marginBottom={355}
      >
        <Img
          src="/images/xroom_ces/xroom_last.png"
          alt="xroom logo"
          // width={203}
          height={30}
          marginTop={95}
        />

        <Stack width={1137} align="center" gap={'58px'}>
          <Stack align="center" gap={'18px'}>
            <h1>
              ALL ON with XROOM. <br /> Dive into the Magic of 3D Storytelling!
            </h1>
            <p>
              Previously available only to broadcast and media professionals, XR
              production is now accessible to everyone with XROOM. Experience
              mesmerizing visual storytelling at your fingertips. XROOM empowers
              alpha gens and video creators to freely explore the world of
              content creation with XR and AI technology.
            </p>
          </Stack>
          <Stack
            className="btn--wrapper"
            width={168}
            align="center"
            gap={'20px'}
          >
            <div
              style={{
                background: 'linear-gradient(135deg, #8E2DE2 0%, #4A00E0 100%)',
                width: '170px',
                padding: '10px 20px',
                color: '#fff',
                textAlign: 'center',
                cursor: 'pointer',
                borderRadius: '4px',
              }}
              onClick={() => {
                window.open(
                  'https://docs.google.com/forms/d/e/1FAIpQLSeGqhJwxOVZaxUx4-iH0LbGmNTpqHCer8-4ZhWuJlQypdg9qw/viewform',
                );
              }}
            >
              CONTACT US
            </div>
          </Stack>
        </Stack>
      </Flex>
    </section>
  );
};

export default MainVisual;
