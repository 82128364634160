import { Box, Button, Flex, Img, Text } from '@chakra-ui/react';
import { ReactElement, ReactNode } from 'react';

type IServiceItemCard = {
  imgName: string;
  title: string;
  sub: string;
  desc: string;
  width: string;
  children?:
  | ReactElement<IServiceItemCard>
  | Array<ReactElement<IServiceItemCard>>;
};

type IServiceItemCardBtn = {
  btnName: ReactNode;
  width?: string;
  onClick: () => void;
};

const ServiceItemCardBtn: React.FC<IServiceItemCardBtn> = ({
  btnName,
  width,
  onClick,
}) => {
  return (
    <Button
      bg="linear-gradient(135deg, #8E2DE2 0%, #4A00E0 100%)"
      onClick={onClick}
      width={width}
      padding="15px 20px"
    >
      {btnName}
    </Button>
  );
};

const ServiceItemCard: React.FC<IServiceItemCard> & {
  Button: React.FC<IServiceItemCardBtn>;
} = ({ width, imgName, title, sub, desc, children }) => {
  return (
    <Flex
      className="service__card"
      width={width}
      direction="column"
      align="center"
      gap="63px"
    >
      <Flex direction="column" align="center">
        <h2>{title}</h2>
        <Flex
          className="service__card__info"
          direction="column"
          align="center"
          gap="17px"
        >
          <Box>
            <Img
              src={`images/xroom_ces/${imgName}.png`}
              alt={`${imgName} image`}
              width={width === '548px' ? '393px' : '677px'}
            />
          </Box>
          <Box
            className="service__card__desc"
            width={width === '548px' ? '393px' : '500px'}
          >
            <Text fontSize="1.5rem" fontWeight={700}>
              {sub}
            </Text>
            <Text fontSize="1.25rem">{desc}</Text>
          </Box>
        </Flex>
      </Flex>
      {children}
    </Flex>
  );
};

ServiceItemCard.Button = ServiceItemCardBtn;

export default ServiceItemCard;
