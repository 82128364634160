import React from 'react';
type InfoProps = {
  imagePath: string;
  title: string;
  subtitle: string;
};

const XRStudio_info: React.FC<InfoProps> = ({ imagePath, title, subtitle }) => {
  return (
    <>
      <div className="p-xxl-5 p-lg-4 p-md-3 p-h-s-2">
        <div className="position-relative">
          <img className="img-fluid" src={imagePath} alt="..." />
          <div className="xrstudiotext">{title}</div>
          <div className="xrstudiotext2">{subtitle}</div>
        </div>
      </div>
    </>
  );
};

export default XRStudio_info;
