import React from 'react';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light absolute-top py-5 fade-in-2"
        id="mainNav"
      >
        <div className="container px-0">
          <Link to="/" style={{ textDecoration: 'none' }}>
            <div className="navbar-brand ml-s-1">
              <div className="soulxLogo"></div>
            </div>
          </Link>
          <button
            className="navbar-toggler navbar-toggler-right"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ms-auto my-lg-0 navbar-s text-start">
              <a href="https://teamsoulx.notion.site/About-SOULX-dfd96103ce8b4b3dae42cc3d0a0f2331" style={{ textDecoration: 'none' }}>
                <li className="nav-item nav-item-about navlink-s">
                  <div className="navi-line"></div>
                  <div className="nav-link fs-h-s-2-1 fs-h-l-0-2 fs-xl-4">
                    About
                  </div>
                </li>
              </a>
              <a href="https://teamsoulx.notion.site/Soulx-Service-16dad1ef709a4eebaf1ca2983e2b4346?pvs=74" style={{ textDecoration: 'none' }}>
                <li className="nav-item nav-item-service navlink-s">
                  <div className="navi-line"></div>
                  <div className="nav-link fs-h-s-2-1 fs-h-l-0-2 fs-xl-4">
                    Service
                  </div>
                </li>
              </a>
              <a href="https://teamsoulx.notion.site/SOULX-Portfolio-bb8555ff22274a5e96ef220f5b248398" style={{ textDecoration: 'none' }}>
                <li className="nav-item nav-item-portfolio navlink-s">
                  <div className="navi-line"></div>
                  <div className="nav-link fs-h-s-2-1 fs-h-l-0-2 fs-xl-4">
                    Portfolio
                  </div>
                </li>
              </a>
              {/* <li className="nav-item nav-item-contact navlink-s">
                <div className="navi-line"></div>
                <a
                  className="nav-link fs-h-s-2-1 fs-h-l-0-2 fs-xl-4"
                  id="navi-contact"
                  href="/#contact"
                >
                  Contact us
                </a>
              </li> */}
              <Link to="/XROOM" style={{ textDecoration: 'none' }}>
                <li className="nav-item nav-item-XROOM navlink-s">
                  <div className="navi-line"></div>
                  <div className="nav-link fs-h-s-2-1 fs-h-l-0-2 fs-xl-4">
                    XROOM
                  </div>
                </li>
              </Link>
              <a href="https://teamsoulx.notion.site/XRoom-Zero-b622aa78a3424d7381786b8d36ff0649" style={{ textDecoration: 'none' }}>
                <li className="nav-item nav-item-XROOM navlink-s">
                  <div className="navi-line"></div>
                  <div className="nav-link fs-h-s-2-1 fs-h-l-0-2 fs-xl-4">
                    XROOM Zero
                  </div>
                </li>
              </a>
            </ul>
            <div
              className="navbar-s-cancel"
              id="navi-cancel"
              data-bs-toggle="collapse"
              data-bs-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            ></div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
