import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import { CES } from 'static/images/Index';

const About: React.FC = () => {
  const youtubeOpts = {
    width: '100%',
    height: '100%',
    playerVars: {
      autoplay: 1,
      playsinline: 1,
      loop: 1,
      playlist: 'c-1BZHhKJgo',
      controls: 0,
      mute: 1,
    },
  };

  return (
    <>
      <section className="about" id="about">
        <div className="container px-4 px-xxl-6 h-100">
          <div className="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-start">
            {/* <YouTube
              // videoId="4NPGY6aGW8M"
              videoId="c-1BZHhKJgo"
              opts={youtubeOpts}
              className="video_player"
            /> */}
            <video controls muted autoPlay loop playsInline width="100%">
              <source
                src="https://asset.soulx.co.kr/soulx-homepage/2024_CES_video3.mp4"
                type="video/mp4"
              />
            </video>

            {/* <div className="d-flex flex-column justify-content-between flex-md-row align-items-center mt-h-l-1-2 mt-h-s-1-1">
              <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-2 d-lg-block text-sm-end me-md-5">
                <img src={CES} className="ces_image"></img>
              </div>
              <div className="col-md-9 col-lg-9 col-xl-9 col-xxl-10">
                <div className="my-4 text-xl-start">
                  <div className="text-white fw-bolder fs-h-l-1-1 fs-xl-4-2 fs-h-s-0">
                    주식회사 소울엑스는 2023년 국제전자제품박람회에 참여합니다.
                  </div>
                  <div className="text-white fw-bolder fs-h-l-1-1 fs-xl-4-2 fs-h-s-0">
                    The SoulX corporation  participates The International Consumer Electronics Show(CES) in 2023
                  </div>
                </div>
              </div>
            </div> */}

            <Fade direction="down" triggerOnce>
              <div className="d-flex mt-h-6-1">
                <div className="col-lg-8 col-xl-7 col-xxl-9">
                  <div className="my-5 text-xl-start">
                    <div className="text-white fw-bolder mb-4 fs-0">SOULX</div>
                    <div className="text-white fw-bolder fs-h-l-1-1 fs-xl-5 fs-h-s-2">
                      세상의 모든 스토리텔러들에게 공간과 소통의 자유를 주다.
                    </div>
                    <div className="text-white mb-6 fs-h-l-1-1 fs-xl-5 fs-h-s-2">
                      Gives freedom of space and expression to all storytellers
                    </div>
                    <div className="gap-3 d-flex justify-content-xl-start">
                      <Link to="/about" className="btn btn-primary btn-xl">
                        Read More &gt;
                      </Link>
                      <a
                        href="https://teamsoulx.notion.site/SOULX-9a616ea1e3f2478cad0bd1462a45c63e"
                        target={'_blank'}
                        className="btn btn-primary btn-xl"
                      >
                        + News &gt;&gt;
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-xxl-3 d-none d-lg-block text-sm-end">
                  <svg
                    width="304"
                    height="380"
                    viewBox="0 0 304 380"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M198.066 182.49H167.137L303.999 0.612H285.257L148.392 182.49H106.192C84.0597 182.49 62.8339 173.698 47.184 158.048C31.534 142.398 22.7419 121.172 22.7419 99.04C22.7419 76.9077 31.534 55.6818 47.184 40.0319C62.8339 24.382 84.0597 15.59 106.192 15.59H243.528V0.612H106.196C80.0917 0.612 55.0564 10.9819 36.5979 29.4404C18.1394 47.899 7.76953 72.9342 7.76953 99.0385C7.76953 125.143 18.1394 150.178 36.5979 168.637C55.0564 187.095 80.0917 197.465 106.196 197.465H137.124L0.256958 379.345H19.005L155.87 197.465H198.07C220.202 197.465 241.428 206.257 257.078 221.907C272.728 237.557 281.52 258.783 281.52 280.915C281.52 303.047 272.728 324.273 257.078 339.923C241.428 355.573 220.202 364.365 198.07 364.365H60.73V379.345H198.066C224.116 379.262 249.071 368.855 267.462 350.406C285.853 331.956 296.18 306.968 296.18 280.917C296.18 254.867 285.853 229.879 267.462 211.429C249.071 192.98 224.116 182.573 198.066 182.49Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
