import About from 'components/soulx/main/about';
import Contact from 'components/soulx/main/contact';
import Service from 'components/soulx/main/service';
import Portfolios from 'components/soulx/main/portfolios';
import NaviIndex from 'components/naviIndex';

const Main: React.FC = () => {
  return (
    <>
      <About></About>
      <Service></Service>
      <Portfolios></Portfolios>
      <Contact></Contact>
      <NaviIndex
        naviStrs={['about', 'service', 'portfolio', 'contact']}
      ></NaviIndex>
    </>
  );
};

export default Main;
