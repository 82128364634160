// import { Box, Flex, Img } from '@chakra-ui/react';
import XroomCESDesktop from 'components/soulx/xroomCES/XroomCESDesktop';
// import useWindowWidth from 'lib/hooks/useWindowWidth';
import { useEffect } from 'react';
import '../../lib/styles/xroom_ces.css';

const XroomCES: React.FC = () => {
  // const currBrowserWidth = useWindowWidth();
  // const [isMobile, setIsMobile] = useState(false);
  // const [isChrome, setIsChrome] = useState(false);

  // useEffect(() => {
  //   if (currBrowserWidth <= 500) setIsMobile(true);
  //   else setIsMobile(false);
  // }, [currBrowserWidth]);

  useEffect(() => {
    const agent = window.navigator.userAgent.toLowerCase();
    // console.log(agent);
    if (agent.includes('chrome')) {
      // setIsChrome(true);
    } else {
      alert('The Web site is optimized for viewing in Chrome');
      // setIsChrome(false);
    }
  }, []);

  // if (!isChrome && currBrowserWidth <= 376) {

  //   return (
  //     <Flex
  //       direction="column"
  //       align="center"
  //       position="relative"
  //       width={'374px'}
  //     >
  //       {/* SVG 배경 이미지 */}
  //       <Img
  //         overflowX="auto"
  //         src={'images/cesLandingMobile.svg'}
  //         alt=""
  //         // w="1920px" // 너비를 100%로 설정
  //         // h="5219px"
  //         // objectFit="contain"
  //       />
  //     </Flex>
  //   );
  // }

  // if (!isChrome && isMobile) {
  //   return (
  //     <Img
  //       overflowX="auto"
  //       src={'images/cesLandingMobile.svg'}
  //       alt=""
  //       // w="1920px" // 너비를 100%로 설정
  //       // h="5219px"
  //       w="100%"
  //       objectFit="contain"
  //     />
  //   );
  // }

  // if (!isChrome && currBrowserWidth <= 1280) {
  //   return (
  //     <Img
  //       overflowX="auto"
  //       src={'images/cesLandingPad.svg'}
  //       alt=""
  //       // w="1920px" // 너비를 100%로 설정
  //       // h="5219px"
  //       w="100%"
  //       objectFit="contain"
  //     />
  //   );
  // }

  // if (!isChrome) {
  //   return (
  //     <Flex
  //       direction="column"
  //       align="center"
  //       position="relative"
  //       width={'calc(1920px - 15px)'}
  //     >
  //       {/* SVG 배경 이미지 */}
  //       <Img
  //         overflowX="auto"
  //         src={'images/cesLanding.svg'}
  //         alt=""
  //         // w="1920px" // 너비를 100%로 설정
  //         // h="5219px"
  //         objectFit="contain"
  //       />
  //     </Flex>
  //   );
  // }

  return (
    // <Flex
    //   direction="column"
    //   align="center"
    //   position="relative"
    //   width={'calc(1920px - 15px)'}
    // >
    //   {/* SVG 배경 이미지 */}
    //   <Img
    //     overflowX="auto"
    //     src={'images/cesLanding.svg'}
    //     alt=""
    //     // w="1920px" // 너비를 100%로 설정
    //     // h="5219px"
    //     objectFit="contain"
    //   />
    // </Flex>
    <main className="xroom_ces">
      <XroomCESDesktop />
    </main>
  );
};

export default XroomCES;
