import XroomWISDesktop from 'components/soulx/xroomCES/XroomWIS';
import '../../lib/styles/xroom_wis.css';

const XroomWIS: React.FC = () => {
  return (
    <main className="xroom_wis">
      <XroomWISDesktop />
    </main>
  );
};

export default XroomWIS;
