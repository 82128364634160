import { AspectRatio } from '@chakra-ui/react';
import YouTube from 'react-youtube';

type IVideo = {
  width: string;
  height: string;
};

const Video: React.FC<IVideo> = ({ width, height }) => {
  const youtubeOpts = {
    width: '100%',
    height: '100%',
    playerVars: {
      autoplay: 1,
      playsinline: 1,
      loop: 1,
      playlist: 'wDZLQNQKAuQ',
      controls: 0,
      mute: 1,
    },
  };
  return (
    <section className="desktop__container video">
      <AspectRatio w={width} h={height}>
        <video controls muted autoPlay loop playsInline width="100%">
          <source
            src="https://asset.soulx.co.kr/soulx-homepage/2024_CES_video3.mp4"
            type="video/mp4"
          />
        </video>
      </AspectRatio>
    </section>
  );
};

export default Video;
