import { Stack, Flex, Text, Box, Img } from '@chakra-ui/react';
import { ReactElement, ReactNode } from 'react';

type IHorizontalCard = {
  children: ReactElement<ICardDesc> | Array<ReactElement<ICardDesc>>;
  className?: string;
};
type IImgBox = {
  imgName: string;
};
type IProps = {
  children: ReactNode;
};
type ICardDesc = {
  width: string;
  sub: string;
  color?: string;
  children: ReactElement<IProps> | Array<ReactElement<IProps>>;
};

const ImgBox: React.FC<IImgBox> = ({ imgName }) => {
  return (
    <Box
      className="contents__img--box"
      width="620px"
      height="437px"
      borderRadius="20px"
      shadow="2px 2px 20px 8px rgba(0, 0, 0, 0.10)"
    >
      <Img src={`images/xroom_ces/${imgName}.png`} alt={`${imgName} image`} />
      {imgName === 'content_interface' && (
        <Img
          className="cursor"
          src="images/xroom_ces/contents_cursor2.svg"
          alt="cursor image"

        />
      )}
    </Box>
  );
};

const Title: React.FC<IProps> = ({ children }) => {
  return <h2>{children}</h2>;
};

const Details: React.FC<IProps> = ({ children }) => {
  return <p>{children}</p>;
};

const CardDesc: React.FC<ICardDesc> = ({ width, sub, children }) => {
  return (
    <Stack
      className="contents__desc"
      width={width}
      gap="10px"
      align="start"
      textAlign="left"
    >
      <Text fontSize="20px" color="#C377FF">
        {sub}
      </Text>
      <Stack gap="20px">{children}</Stack>
    </Stack>
  );
};

const HorizontalCard: React.FC<IHorizontalCard> & {
  Img: React.FC<IImgBox>;
  Title: React.FC<IProps>;
  Details: React.FC<IProps>;
  Desc: React.FC<ICardDesc>;
} = ({ children, className }) => {
  return (
    <Flex
      gap="40px"
      align="center"
      className={`contents__card ${className}`}
      wrap="wrap"
    >
      {children}
    </Flex>
  );
};

HorizontalCard.Title = Title;
HorizontalCard.Details = Details;
HorizontalCard.Desc = CardDesc;
HorizontalCard.Img = ImgBox;

export default HorizontalCard;
