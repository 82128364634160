type ModalProps = {
  open: boolean;
  children: React.ReactNode;
};

const Modal: React.FC<ModalProps> = ({ open, children }) => {
  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  //   const { open } = props;

  return (
    // 모달이 열릴때 openModal 클래스가 생성된다.
    <div className={open ? 'openModal modal' : 'modal'}>
      {open ? (
        <section>
          <main>{children}</main>
        </section>
      ) : null}
    </div>
  );
};

export default Modal;
