import { initializeApp } from 'firebase/app';

// Import the functions you need from the SDKs you need
// import { getAnalytics } from 'firebase/analytics';
// import { getFirestore } from 'firebase/firestore';
import { getAuth } from '@firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
interface FirebaseConfig {
  apiKey?: string;
  authDomain?: string;
  projectId?: string;
  storageBucket?: string;
  messagingSenderId?: string;
  appId?: string;
  measurementId?: string;
}

const developmentConfig: FirebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY_DEV,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN_DEV,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID_DEV,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET_DEV,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID_DEV,
  appId: process.env.REACT_APP_FIREBASE_APPID_DEV,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID_DEV,
};

const productionConfig: FirebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY_PRO,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN_PRO,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID_PRO,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET_PRO,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID_PRO,
  appId: process.env.REACT_APP_FIREBASE_APPID_PRO,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID_PRO,
};

const firebaseConfig: FirebaseConfig =
  process.env.NODE_ENV === 'development' ? developmentConfig : productionConfig;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// const db = getFirestore(app);
export const auth = getAuth(app);
export { app };
