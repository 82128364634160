import React from 'react';
import { Fade } from 'react-awesome-reveal';

const SoulX: React.FC = () => {
  return (
    <>
      <section className="soulx" id="soulx">
        <Fade direction="left" triggerOnce>
          <div className="container px-xxl-6 text-start">
            <div className="row gx-5 align-items-center justify-content-center">
              <div className="col-lg-8 col-xl-7 col-xxl-7-1">
                <div className="my-5 text-xl-start">
                  <div className="text-white fw-bolder mb-5-2 fs-h-l-4 fs-h-s-4">
                    SOULX
                  </div>
                  <div className="text-white fw-bolder fs-h-l-2 fs-h-s-2 float-start">
                    COMPANY
                  </div>
                  <div className="text-white fw-light fs-h-l-1 fs-h-s-1 lh-lg pt-h-l-1 word-keep">
                    &nbsp;&nbsp;소울엑스는 최신 XR 기술력과 차별화된 컨셉 및
                    세계관을 기반으로 가상환경을 활용해 메타버스 시대에 최적화된
                    초실감형 솔루션 및 콘텐츠를 제공합니다. 다양한 스토리텔링
                    방식 (디지털 콘텐츠 제작, 프로덕트 제작, 게임, 브랜딩, 전시
                    등)으로 XR(가상공간)을 활용하는 소프트웨어와 플랫폼 개발을
                    통해 사람들의 창작에 소통을 더하는 방식으로 비즈니스를
                    진행하고 있습니다.
                  </div>
                  <div className="mb-4-4 text-white fw-extralight lh-lg">
                    SOULX provides immersive solutions and contents optimized
                    for the metaverse era based on the latest XR technology and
                    differentiated concepts and worldviews. We are doing
                    business that adds communication to people's creation with
                    solutions through through storytelling in various ways
                    (digital content production, product production, games,
                    branding, exhibitions, etc.).
                  </div>
                  <div className="text-white fw-bolder fs-h-l-2 fs-h-s-2 float-start">
                    TEAM SOULX
                  </div>
                  <div className="text-white fw-light fs-h-l-1 fs-h-s-1 lh-lg pt-h-l-1 word-keep">
                    &nbsp;&nbsp;소울엑스는 게임엔진을 활용한 실시간 3D 영상합성
                    기술 및 이를 활용한 프로그램에 관한 기술특허(등록번호
                    10-2192412 (2020.12.11))를 보유하고 있습니다. 소울엑스는
                    VR/AR, AI, 자율주행 등 신기술에 대한 이해를 바탕으로 문화와
                    예술, 경험가치를 담은 프로젝트를 통해 퀄리티의 차별화를
                    추구합니다. 큐레이팅, 브랜딩 디자인, 건축, 생명공학,
                    소비자주거 등 팀 멤버 각자 다양한 분야의 경험에 기반한
                    가상공간 및 VR콘텐츠 해석을 통해 남다른 감각과 시각으로
                    소울웨어 콘텐츠를 만들어가고 있습니다.{' '}
                  </div>
                  <div className="mb-6 text-white fw-extralight lh-lg">
                    SOULX holds a technology patent (registration No.
                    10-2192412) for ‘a real-time 3D image synthesis technology
                    using a game engine and a program using the technology’.
                    Based on an understanding of new technologies such as VR/AR,
                    AI, and autonomous driving, we pursue quality
                    differentiation through projects that contain culture, art,
                    and experience values. Through interpretation based on the
                    diverse experiences of team members such as computer
                    engineering, visual design, art and culture curating,
                    architecture, and branding, we are creating soulware
                    contents with a unique sense and perspective.
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-xxl-4-1 d-none d-xl-block text-sm-end">
                <svg
                  width="304"
                  height="380"
                  viewBox="0 0 304 380"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M198.066 182.49H167.137L303.999 0.612H285.257L148.392 182.49H106.192C84.0597 182.49 62.8339 173.698 47.184 158.048C31.534 142.398 22.7419 121.172 22.7419 99.04C22.7419 76.9077 31.534 55.6818 47.184 40.0319C62.8339 24.382 84.0597 15.59 106.192 15.59H243.528V0.612H106.196C80.0917 0.612 55.0564 10.9819 36.5979 29.4404C18.1394 47.899 7.76953 72.9342 7.76953 99.0385C7.76953 125.143 18.1394 150.178 36.5979 168.637C55.0564 187.095 80.0917 197.465 106.196 197.465H137.124L0.256958 379.345H19.005L155.87 197.465H198.07C220.202 197.465 241.428 206.257 257.078 221.907C272.728 237.557 281.52 258.783 281.52 280.915C281.52 303.047 272.728 324.273 257.078 339.923C241.428 355.573 220.202 364.365 198.07 364.365H60.73V379.345H198.066C224.116 379.262 249.071 368.855 267.462 350.406C285.853 331.956 296.18 306.968 296.18 280.917C296.18 254.867 285.853 229.879 267.462 211.429C249.071 192.98 224.116 182.573 198.066 182.49Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    </>
  );
};

export default SoulX;
