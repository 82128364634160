import React from 'react';
import { Fade } from 'react-awesome-reveal';
import virtualstage_db from 'db/xr_virtualstage.json';

const VirtualStage: React.FC = () => {
  return (
    <>
      <section className="xr_2 bg-black" id="xr_2">
        <Fade direction="left" triggerOnce>
          <div className="container px-xxl-6">
            <div className="row gx-5 align-items-center">
              <div className="my-5 text-xl-start">
                <div className="">
                  <div className="text-white fw-bolder fs-1">VIRTUAL STAGE</div>
                  <div className="text-white fw-normal mb-5-1 fs-5">
                    가상공간 무대
                  </div>
                </div>
                <div className="gridTest">
                  <div className="one">
                    <img
                      className="img-fluid"
                      src={virtualstage_db.imagePaths[0]}
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      className="img-fluid"
                      src={virtualstage_db.imagePaths[1]}
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      className="img-fluid"
                      src={virtualstage_db.imagePaths[2]}
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      className="img-fluid"
                      src={virtualstage_db.imagePaths[3]}
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      className="img-fluid"
                      src={virtualstage_db.imagePaths[4]}
                      alt="..."
                    />
                  </div>
                  <div className="six">
                    <img
                      className="img-fluid"
                      src={virtualstage_db.imagePaths[5]}
                      alt="..."
                    />
                  </div>
                </div>
                <div className="gridTest">
                  <div className="seven">
                    <img
                      className="img-fluid"
                      src={virtualstage_db.imagePaths[6]}
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      className="img-fluid"
                      src={virtualstage_db.imagePaths[7]}
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      className="img-fluid"
                      src={virtualstage_db.imagePaths[8]}
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      className="img-fluid"
                      src={virtualstage_db.imagePaths[9]}
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      className="img-fluid"
                      src={virtualstage_db.imagePaths[10]}
                      alt="..."
                    />
                  </div>
                  <div className="">
                    <img
                      className="img-fluid"
                      src={virtualstage_db.imagePaths[11]}
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    </>
  );
};

export default VirtualStage;
