import { Box, Image, Text } from '@chakra-ui/react';

export type IImgCard = {
  imgName: string;
  desc?: string;
  isHorizontal?: boolean;
};

const ImgCard: React.FC<IImgCard> = ({
  imgName,
  desc,
  isHorizontal = false,
}) => {
  return (
    <Box
      className="img_card"
      w={isHorizontal ? '620px' : '400px'}
      height="324px"
      borderRadius={20}
      position="relative"
      display="flex"
      justifyContent="center"
    >
      <Image
        src={`images/xroom_ces/${imgName}`}
        alt={`${desc} image`}
        position="absolute"
      />
      {isHorizontal && (
        <Text
          position="absolute"
          bottom={22}
          fontSize="1.5rem"
          fontWeight={700}
        >
          {desc}
        </Text>
      )}
    </Box>
  );
};

export default ImgCard;
