import React from 'react';
import { Fade } from 'react-awesome-reveal';
import xrstudio_db from 'db/xr_studio.json';
import XRStudio_info from './xrstudio_info';

const XRStudio: React.FC = () => {
  const infoImageList: JSX.Element[] = xrstudio_db.studio.map((item, idx) => (
    <XRStudio_info
      imagePath={item.imagePath}
      title={item.title}
      subtitle={item.subtitle}
      key={idx}
    ></XRStudio_info>
  ));

  return (
    <>
      <section className="xr_1 bg-black" id="xr_1">
        <Fade direction="left" triggerOnce>
          <div className="container px-xxl-6">
            <div className="row gx-5 align-items-center">
              <div className="my-5 text-xl-start">
                <div className="xr_studio_main">
                  <div className="xr_flex col-xxl-6 col-xl-6 col-lg-6">
                    <div className="text-white mb-6-1">
                      <div className="mb-4">
                        <span className="fs-4-2">| XR Event&nbsp;</span>
                        <span className="fs-4 fw-extralight">
                          XR 이벤트 기획 및 진행 대행
                        </span>
                      </div>
                      <div className="mb-4">
                        <span className="fs-h-l-1-2 fs-h-s-2-1 inline-w-100-sm">
                          XR 라이브 행사
                        </span>
                        <span className="fs-h-l-1-1 fs-h-s-2 fw-extralight">
                          (비대면 컨퍼런스, 데모데이, 기업 행사, 네트워킹,
                          이벤트)
                        </span>
                        <div className="fs-h-l-0-2 fs-h-s-1-1">
                          XR Live Event Planning/Operating Service
                        </div>
                        <div className="fs-h-l-0-1-1 fs-h-s-1 fw-extralight">
                          Metaverse Conference, Demo Day, Business Event,
                          Networking, etc.
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="fs-h-l-1-2 fs-h-s-2-1">
                          라이브 커머스
                        </div>
                        <div className="fs-h-l-0-2 fs-h-s-1-1">
                          Live Commerce
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="fs-h-l-1-2 fs-h-s-2-1">
                          온라인 비대면 교육, 강의
                        </div>
                        <div className="fs-h-l-0-2 fs-h-s-1-1">
                          Ontact Education, Lecture
                        </div>
                      </div>
                      <div className="mb-4">
                        <span className="fs-h-l-1-2 fs-h-s-2-1">
                          메타버스 공연, 전시
                        </span>
                        <span className="fs-h-l-1-1 fs-h-s-2 fw-extralight">
                          (가상 스테이지 쇼, 가상 전시관)
                        </span>
                        <div className="fs-h-l-0-2 fs-h-s-1-1">
                          Metaverse Show, Exhibition
                        </div>
                        <div className="fs-h-l-0-1-1 fs-h-s-1 fw-extralight">
                          Virtual Stage, Virtual Exhibition Hall
                        </div>
                      </div>
                      <div className="mb-4">
                        <span className="fs-h-l-1-2 fs-h-s-2-1">
                          XR 스튜디오 솔루션
                        </span>
                        <span className="fs-h-l-1-1 fs-h-s-2 fw-extralight">
                          (장비 구성 및 세팅)
                        </span>
                        <div className="fs-h-l-0-2 fs-h-s-1-1">
                          XR Studio Solution
                        </div>
                        <div className="fs-h-l-0-1-1 fs-h-s-1 fw-extralight">
                          XR Equipment System Sales & Rental{' '}
                        </div>
                      </div>
                      <div className="mb-4">
                        <span className="fs-h-l-1-2 fs-h-s-2-1">
                          LED 미디어월
                        </span>
                        <span className="fs-h-l-1-1 fs-h-s-2 fw-extralight">
                          (렌탈 및 설치 지원)
                        </span>
                        <div className="fs-h-l-0-2 fs-h-s-1-1">
                          LED Media Wall
                        </div>
                      </div>
                    </div>

                    <div className="text-white mb-6-1">
                      <div className="mb-4">
                        <span className="fs-4-2">| XR Content&nbsp;</span>
                        <span className="fs-4 fw-extralight">
                          XR 콘텐츠 개발
                        </span>
                      </div>
                      <div className="mb-4">
                        <span className="fs-h-l-1-2 fs-h-s-2-1">
                          XR 콘텐츠 개발
                        </span>
                        <span className="fs-h-l-1-1 fs-h-s-2 fw-extralight">
                          (게임, 체험 프로그램 등)
                        </span>
                        <div className="fs-h-l-0-2 fs-h-s-1-1">
                          XR Content Dev.
                        </div>
                        <div className="fs-h-l-0-1-1 fs-h-s-1 fw-extralight">
                          Game, Experience Program, etc.
                        </div>
                      </div>
                      <div className="mb-4">
                        <span className="fs-h-l-1-2 fs-h-s-2-1">
                          버추얼 프로덕션
                        </span>
                        <span className="fs-h-l-1-1 fs-h-s-2 fw-extralight">
                          (광고, 영화, 드라마, 애니메이션 등)
                        </span>
                        <div className="fs-h-l-0-2 fs-h-s-1-1">
                          Virtual Production
                        </div>
                        <div className="fs-h-l-0-1-1 fs-h-s-1 fw-extralight">
                          Commercial AD, Film, Drama, Animation, etc.
                        </div>
                      </div>
                      <div className="mb-4">
                        <span className="fs-h-l-1-2 fs-h-s-2-1 inline-w-100-sm">
                          VR 시뮬레이션 프로그램 개발
                        </span>
                        <span className="fs-h-l-1-1 fs-h-s-2 fw-extralight">
                          (스마트팩토리, 스마트시티, 자율주행, AI 등)
                        </span>
                        <div className="fs-h-l-0-2 fs-h-s-1-1">
                          VR Simulation Program Dev.
                        </div>
                        <div className="fs-h-l-0-1-1 fs-h-s-1 fw-extralight">
                          Smart Factory, Smart City, Autonomous Driving, AI,
                          etc.
                        </div>
                      </div>
                    </div>

                    <div className="text-white">
                      <div className="mb-4">
                        <span className="fs-4-2">| Branded Content&nbsp;</span>
                        <span className="fs-4 fw-extralight">
                          {' '}
                          브랜드 개발 및 홍보
                        </span>
                      </div>
                      <div className="mb-4">
                        <span className="fs-h-l-1-2 fs-h-s-2-1">
                          브랜드 개발
                        </span>
                        <span className="fs-h-l-1-1 fs-h-s-2 fw-extralight">
                          (BI, BX, CX)
                        </span>
                        <div className="fs-h-l-0-2 fs-h-s-1-1">
                          Branding Dev.
                        </div>
                      </div>
                      <div className="mb-4">
                        <span className="fs-h-l-1-2 fs-h-s-2-1">
                          제품 홍보 기획 및 제작
                        </span>
                        <span className="fs-h-l-1-1 fs-h-s-2 fw-extralight">
                          (스틸 및 영상)
                        </span>
                        <div className="fs-h-l-0-2 fs-h-s-1-1">
                          Product Promo Marketing
                        </div>
                      </div>
                      <div className="mb-4">
                        <span className="fs-h-l-1-2 fs-h-s-2-1">
                          숏폼 콘텐츠 제작 및 관리 대행
                        </span>
                        <div className="fs-h-l-0-2 fs-h-s-1-1">
                          Short-form Content Production & Management
                        </div>
                      </div>
                      <div className="mb-4">
                        <span className="fs-h-l-1-2 fs-h-s-2-1">
                          3D 버추얼 콘텐츠 제작
                        </span>
                        <div className="fs-h-l-0-2 fs-h-s-1-1">
                          3D Virtual Content Production
                        </div>
                        <div className="fs-h-l-0-1-1 fs-h-s-1 fw-extralight">
                          Virtual Human, Character, etc.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="xr_flex col-xxl-6 col-xl-6 col-lg-6 service_w-40-xxl">
                    {infoImageList}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    </>
  );
};

export default XRStudio;
