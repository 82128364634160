import Awards from 'components/soulx/about/awards';
import History from 'components/soulx/about/history';
import SoulX from 'components/soulx/about/soulx';
import NaviIndex from 'components/naviIndex';

const About: React.FC = () => {
  return (
    <>
      <SoulX></SoulX>
      <Awards></Awards>
      <History></History>
      <NaviIndex naviStrs={['soulx', 'awards', 'history']}></NaviIndex>
    </>
  );
};

export default About;
