import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';

const Service: React.FC = () => {
  return (
    <>
      <section className="service" id="service">
        <Fade direction="down" className="h-100" triggerOnce>
          <div className="container px-4 px-xxl-6 h-100">
            <div className="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-start ">
              <div className="">
                <div className="my-5 text-xl-start">
                  <div className="text-white fw-bolder mb-4 fs-4-2">
                    Service
                  </div>
                  <div className="text-white fs-h-l-1-1 fs-h-s-2 word-keep text-preline lh-2 w-lg-60">
                    소울엑스는 XR 기반 기술을 활용해 새로운 문화와 예술, 경험
                    가치를 담는 크리에이티브 그룹으로서 3D/XR 콘텐츠의 개발,
                    프로덕션부터 브랜드, 제품의 마케팅 콘텐츠까지 고퀄리티의
                    부가가치가 있는 디지털 자산 솔루션을 제공합니다.
                  </div>
                  <div className="text-white fw-extralight mb-6 fs-h-l-1-1 fs-h-s-2 word-keep text-preline lh-2 w-lg-60">
                    SOULX is a creative group that uses XR-based technology to
                    provide new culture, art, and experience values. We provides
                    high-quality, value-added digital asset solutions from 3D/XR
                    content development and production to marketing content for
                    brands and products.
                  </div>
                  <div className="gap-3 d-flex justify-content-xl-start">
                    <a className="btn btn-primary btn-xl" href="/service">
                      Read More &gt;
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    </>
  );
};

export default Service;
