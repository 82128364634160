// import { Box, Flex, Img } from '@chakra-ui/react';
import XroomSXSWDesktop from 'components/soulx/xroomCES/XroomSXSWDesktop';
// import useWindowWidth from 'lib/hooks/useWindowWidth';
import { useEffect, useState } from 'react';
import '../../lib/styles/xroom_ces.css';

const XroomSXSW: React.FC = () => {
  const [isChrome, setIsChrome] = useState<boolean>(false);
  console.log(isChrome);
  useEffect(() => {
    const agent = window.navigator.userAgent.toLowerCase();
    setIsChrome(agent.includes('chrome'));
  }, []);

  // useEffect(() => {
  //   console.log(isChrome);
  //   if (!isChrome) {
  //     const link = document.createElement('link');
  //     link.href = '/xrrom_ces_test.css';
  //     link.rel = 'stylesheet';
  //     document.head.appendChild(link);

  //     return () => {
  //       document.head.removeChild(link);
  //     };
  //   }
  // }, [isChrome]);
  // console.log('브라우저', navigator.userAgent);
  // if (
  //   navigator.userAgent.indexOf('Safari') != -1 &&
  //   navigator.userAgent.indexOf('Chrome') == -1
  // ) {
  //   setIsChrome('safari');
  // }
  // const currBrowserWidth = useWindowWidth();
  // const [isMobile, setIsMobile] = useState(false);

  // useEffect(() => {
  //   console.log('currBrowserWidth', currBrowserWidth);

  //   if (currBrowserWidth <= 500) setIsMobile(true);
  //   else setIsMobile(false);
  // }, [currBrowserWidth]);

  // useEffect(() => {
  //   const agent = window.navigator.userAgent.toLowerCase();
  //   setIsChrome(agent.includes('chrome'));
  // }, []);

  // useEffect(() => {
  //   if (isChrome) {
  //     import('../../../public/xrrom_ces_test.css')
  //       .then(() => {
  //         console.log('Chrome CSS loaded');
  //       })
  //       .catch((error) => {
  //         console.error('Failed to load Chrome CSS:', error);
  //       });
  //   } else {
  //     import('../../../src/lib/styles/xroom_ces.css')
  //       .then(() => {
  //         console.log('Other browser CSS loaded');
  //       })
  //       .catch((error) => {
  //         console.error('Failed to load other browser CSS:', error);
  //       });
  //   }
  // }, [isChrome]);

  // useEffect(() => {
  //   const agent = window.navigator.userAgent.toLowerCase();
  //   if (agent.includes('chrome')) {
  //     setIsChrome(true);
  //   } else {
  //     alert('The Web site is optimized for viewing in Chrome');
  //     // setIsChrome(false);
  //   }
  // }, []);

  // if (!isChrome && currBrowserWidth <= 430) {
  //   return (
  //     <Flex
  //       direction="column"
  //       align="center"
  //       position="relative"
  //       width={'430px'}
  //     >
  //       {/* SVG 배경 이미지 */}
  //       <Img
  //         overflowX="auto"
  //         src={'images/cesLandingMobile.svg'}
  //         alt=""
  //         // w="1920px" // 너비를 100%로 설정
  //         // h="5219px"
  //         // objectFit="contain"
  //       />
  //     </Flex>
  //   );
  // }

  // 사파리용
  // if (isMobile) {
  //   return (
  //     <Img
  //       overflowX="auto"
  //       src={'images/cesLandingMobile.svg'}
  //       alt=""
  //       // w="1920px" // 너비를 100%로 설정
  //       // h="5219px"
  //       w="100%"
  //       objectFit="contain"
  //     />
  //   );
  // }
  // // 사파리용
  // if ( currBrowserWidth <= 1280) {
  //   return (
  //     <Img
  //       overflowX="auto"
  //       src={'images/cesLandingPad.svg'}
  //       alt=""
  //       // w="1920px" // 너비를 100%로 설정
  //       // h="5219px"
  //       w="100%"
  //       objectFit="contain"
  //     />
  //   );
  // }

  // if (!isChrome) {
  //   return (
  //     <Flex
  //       direction="column"
  //       align="center"
  //       position="relative"
  //       width={'calc(1920px - 15px)'}
  //     >
  //       {/* SVG 배경 이미지 */}
  //       <Img
  //         overflowX="auto"
  //         src={'images/cesLanding.svg'}
  //         alt=""
  //         // w="1920px" // 너비를 100%로 설정
  //         // h="5219px"
  //         objectFit="contain"
  //       />
  //     </Flex>
  //   );
  // }

  return (
    // <Flex
    //   direction="column"
    //   align="center"
    //   position="relative"
    //   width={'calc(1920px - 15px)'}
    // >
    //   {/* SVG 배경 이미지 */}
    //   <Img
    //     overflowX="auto"
    //     src={'images/cesLanding.svg'}
    //     alt=""
    //     // w="1920px" // 너비를 100%로 설정
    //     // h="5219px"
    //     objectFit="contain"
    //   />
    // </Flex>
    <main className={`xroom_ces ${isChrome ? 'chrome' : ''}`}>
      {/* <link rel="stylesheet" type="text/css" href="/xrrom_ces_test.css"></link> */}
      <XroomSXSWDesktop />
    </main>
  );
};

export default XroomSXSW;
