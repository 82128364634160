import React from 'react';
import Portfolio_Portfolio from './portfolio_portfolio';
import works_db from 'db/works.json';
import { Fade } from 'react-awesome-reveal';

const Portfolio_02: React.FC = () => {
  const workImageList: JSX.Element[] = works_db.works_02.map((item, idx) => (
    <Portfolio_Portfolio
      imagePath={item.imagePath}
      title={item.title}
      subtitle={item.subtitle}
      key={idx}
    ></Portfolio_Portfolio>
  ));

  return (
    <>
      <section className="page-section portfolio_02" id="portfolio_02">
        <Fade direction="right" triggerOnce>
          <div className="container mw-s-1 mt-h-l-1-2 text-start">
            <div className="row px-l-1">{workImageList}</div>
          </div>
        </Fade>
      </section>
    </>
  );
};

export default Portfolio_02;
