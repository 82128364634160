import React from 'react'; // 추가

import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { FunctionComponent, ReactElement, ReactNode, SVGProps } from 'react';
import { IImgCard } from './ImgCard';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

type IFeatureBox = {
  FeatureBoxDesktop: FunctionComponent<SVGProps<SVGSVGElement>>;
  children:
  | ReactElement<IFeatureBoxInfos>
  | ReactElement<IFeatureBoxContents>
  | Array<ReactElement<IFeatureBoxInfos> | ReactElement<IFeatureBoxContents>>;
  className?: string;
  height: number;
};

type IProps = {
  children: ReactNode;
};

type IFeatureBoxInfos = {
  sub: string;
  color?: string;
  children: ReactElement<IProps> | Array<ReactElement<IProps>>;
};

type IFeatureBoxContents = {
  children: ReactElement<IImgCard> | Array<ReactElement<IImgCard>>;
};

const Title: React.FC<IProps> = ({ children }) => {
  return <h2>{children}</h2>;
};

const Details: React.FC<IProps> = ({ children }) => {
  return <p>{children}</p>;
};

const FeatureBoxInfos: React.FC<IFeatureBoxInfos> = ({
  sub,
  children,
  color = '#C377FF',
}) => {
  return (
    <Stack gap="12px" className="title__container">
      <Text className="sub_txt" fontSize="20px" color={color}>
        {sub}
      </Text>
      <Stack gap="20px">{children}</Stack>
    </Stack>
  );
};

const FeatureBoxContents: React.FC<IFeatureBoxContents> = ({ children }) => {
  return (
    <Flex width={1280} wrap="wrap" gap="40px 20px" className="contents">
      {children}
    </Flex>
  );
};

const FeatureBox: React.FC<IFeatureBox> & {
  Infos: React.FC<IFeatureBoxInfos>;
  Title: React.FC<IProps>;
  Details: React.FC<IProps>;
  Contents: React.FC<IFeatureBoxContents>;
} = ({ FeatureBoxDesktop, children, className, height }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1 } },
  };

  return (
    <motion.section
      className={`desktop__container featurebox ${className}`}
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={fadeIn}
    >
      <Box width={1482} height={height}>
        <FeatureBoxDesktop />
        <Stack className="featurebox__info">{children}</Stack>
      </Box>
    </motion.section>
  );
};

FeatureBox.Infos = FeatureBoxInfos;
FeatureBox.Title = Title;
FeatureBox.Details = Details;
FeatureBox.Contents = FeatureBoxContents;

export default FeatureBox;