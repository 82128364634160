import React from 'react';
type ClientProps = {
  imagePath: string;
};

const Client: React.FC<ClientProps> = ({ imagePath }) => {
  return (
    <>
      <div className="col-2-1 mb-4">
        <div className="portfolio-item">
          <img className="img-fluid mb-4-2" src={imagePath} alt="..." />
        </div>
      </div>
    </>
  );
};

export default Client;
