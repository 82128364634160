import { useEffect, useRef } from 'react';
import { Box, Text, Button, useBreakpointValue, Img } from '@chakra-ui/react';

const Section = ({ bgColor, children, ref }: any) => {
  const padding = useBreakpointValue({
    base: '0 16px',
    sm: '0 16px ',
    md: '0 32px',
    xl: '0 320px',
  });
  return (
    <Box bg={bgColor} p={padding} ref={ref}>
      {children}
    </Box>
  );
};

const TextArea = ({ title, content }: any) => {
  const TitleFontSize = useBreakpointValue({
    base: '24px',
    sm: '24px',
    md: '48px',
    xl: '48px',
  });

  const CtnFontSize = useBreakpointValue({
    base: '15px',
    sm: '15px',
    md: '20px',
    xl: '20px',
  });

  const layout = useBreakpointValue({
    base: 'block',
    sm: 'block',
    md: 'block',
    xl: 'flex',
  });

  const mb = useBreakpointValue({
    base: '20px',
    sm: '20px ',
    md: '28px',
    xl: '0px',
  });
  return (
    <Box display={layout} justifyContent="space-between">
      <Text
        fontSize={TitleFontSize}
        fontWeight="700"
        width={useBreakpointValue({
          base: '100%',
          sm: '100%',
          md: '100%',
          xl: '560px',
        })}
        lineHeight={useBreakpointValue({
          base: 'auto',
          sm: 'auto',
          md: 'auto',
          xl: '70px',
        })}
        letterSpacing={useBreakpointValue({
          base: 'auto',
          sm: 'auto',
          md: 'auto',
          xl: '-1.44px',
        })}
      >
        {title}
      </Text>
      <Text
        fontSize={CtnFontSize}
        w={useBreakpointValue({
          base: '100',
          sm: '100%',
          md: '100%',
          xl: '660px',
        })}
        mt={mb}
        color={'#6B6C74'}
        lineHeight={useBreakpointValue({
          base: '20px',
          sm: '20px',
          md: '30px',
          xl: '28px',
        })}
        letterSpacing={useBreakpointValue({
          base: '-0.3px',
          sm: '-0.3px',
          md: '-0.4px',
          xl: '-0.4px',
        })}
      >
        {content}
      </Text>
    </Box>
  );
};

const XroomWISDesktop: React.FC = () => {
  const padding = useBreakpointValue({
    base: '0 16px',
    sm: '0 16px',
    md: '0 32px 195px',
    xl: '0 320px 132px',
  });
  const heightM = useBreakpointValue({
    base: '100vh',
    sm: '100vh',
    md: 'auto',
    xl: 'auto',
  });

  const fontSize = useBreakpointValue({
    base: '16px',
    sm: '16px',
    md: '20px',
    xl: '36px',
  });

  const fontPadding = useBreakpointValue({
    base: '12px 0 8px',
    sm: '12px 0 8px',
    md: '20px 0 24px',
    xl: '32px 0 12px',
  });

  const ImgHeight = useBreakpointValue({
    base: '27px',
    sm: '27px',
    md: '46px',
    xl: '57px',
  });

  const SectionPt = useBreakpointValue({
    base: '48px',
    sm: '48px',
    md: '90px',
    xl: '166px',
  });

  const SectionPt2 = useBreakpointValue({
    base: '0px',
    sm: '0px',
    md: '0px',
    xl: '180px',
  });

  const firstSectionRef = useRef<HTMLDivElement>(null);
  const secondSectionRef = useRef<HTMLDivElement>(null);
  const thridSectionRef = useRef<HTMLDivElement>(null);
  const fourthSectionRef = useRef<HTMLDivElement>(null);
  const fifthSectionRef = useRef<HTMLDivElement>(null);
  const sixthSectionRef = useRef<HTMLDivElement>(null);
  const seventhSectionRef = useRef<HTMLDivElement>(null);
  const eighthSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (firstSectionRef.current) {
      const observer = new IntersectionObserver((elements) => {
        elements.forEach((el) =>
          el.target.animate([{ opacity: 0 }, { opacity: 1 }], {
            duration: 800,
            delay: 300,
            easing: 'ease-out',
            fill: 'both',
          }),
        );
      });

      if (firstSectionRef.current) {
        observer.observe(firstSectionRef.current);
      }
      if (secondSectionRef.current) {
        observer.observe(secondSectionRef.current);
      }
      if (thridSectionRef.current) {
        observer.observe(thridSectionRef.current);
      }
      if (fourthSectionRef.current) {
        observer.observe(fourthSectionRef.current);
      }
      if (fifthSectionRef.current) {
        observer.observe(fifthSectionRef.current);
      }
      if (sixthSectionRef.current) {
        observer.observe(sixthSectionRef.current);
      }
      if (seventhSectionRef.current) {
        observer.observe(seventhSectionRef.current);
      }
      if (eighthSectionRef.current) {
        observer.observe(eighthSectionRef.current);
      }
    }
  }, [firstSectionRef]);
  return (
    <>
      <Box display="flex" flexDirection="column">
        {/* 메인 */}
        <Box
          ref={firstSectionRef}
          p={padding}
          backgroundImage="url('images/xroom_wis/mainBg.png')"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          height={heightM}
          display={'flex'}
          alignItems={useBreakpointValue({
            base: 'center',
            sm: 'center',
            md: 'center',
            xl: 'flex-start',
          })}
          justifyContent={'center'}
          flexDirection={'column'}
        >
          <Img
            src="/images/xroom_wis/logo.png"
            alt="xroom_logo"
            // width={203}
            height={ImgHeight}
            marginTop={useBreakpointValue({
              base: 'none',
              sm: 'none',
              md: '114px',
              xl: '114px',
            })}
          />
          <Text fontSize={fontSize} p={fontPadding}>
            Experience all that 3D storytelling brings with XROOM!
          </Text>
          <Text
            fontSize={useBreakpointValue({
              base: '13px',
              sm: '13px',
              md: '14px',
              xl: '20px',
            })}
            pb={useBreakpointValue({
              base: '28px',
              sm: '28px',
              md: '20px',
              xl: '24px',
            })}
          >
            Studio XAM is a foreign trademarked name for XRoom, currently
            pending.
          </Text>
          <video controls muted loop playsInline width="100%" autoPlay>
            <source
              src="https://asset.soulx.co.kr/soulx-homepage/2024_CES_video3.mp4"
              type="video/mp4"
            />
          </video>
        </Box>
        <Box
          ref={secondSectionRef}
          p={useBreakpointValue({
            base: '0 16px',
            sm: '0 16px',
            md: '0 32px',
            xl: '0 320px',
          })}
        >
          <Box pt={SectionPt}>
            <TextArea
              title={useBreakpointValue({
                base: <>The magic of virtual world in real time</>,
                xl: <>The magic of virtual world in real time</>,
                default: 'The magi c of virtual world in real time',
              })}
              content={
                <>
                  Experience true immersion with high-quality real-time
                  rendering powered by a game engine! XROOM's technology
                  enhances immersion with photorealistic graphics.
                </>
              }
            />
            <Img
              src="/images/xroom_wis/rectangle.jpg"
              alt="rectangle"
              marginTop={useBreakpointValue({
                base: '32px',
                sm: '32px',
                md: '52px',
                xl: '48px',
              })}
            />
          </Box>
        </Box>
        <Box
          ref={thridSectionRef}
          p={useBreakpointValue({
            base: '0 16px',
            sm: '0 16px',
            md: '0 32px',
            xl: '0 320px',
          })}
        >
          <Box
            mt={useBreakpointValue({
              base: '120px',
              sm: '120px',
              md: '200px',
              xl: '240px',
            })}
          >
            <TextArea
              title={useBreakpointValue({
                base: <>Video production through spatial storytelling</>,
                xl: <>Video production through spatial storytelling</>,
                default: 'Video production through spatial storytelling',
              })}
              content={
                <>
                  XROOM offers user-friendly UI, flexible camera control, easy
                  tutorials for story sharing, real-time collaboration, and
                  diverse content creation opportunities.
                </>
              }
            />
            <Img
              src={useBreakpointValue({
                base: '/images/xroom_wis/mobileList_en.png',
                sm: '/images/xroom_wis/mobileList_en.png',
                md: '/images/xroom_wis/rectangle_en.png',
                xl: '/images/xroom_wis/rectangle_en.png',
              })}
              alt="groupImg"
              marginTop={useBreakpointValue({
                base: '32px',
                sm: '32px',
                md: '52px',
                xl: '48px',
              })}
              marginBottom={useBreakpointValue({
                base: '120px',
                sm: '120px',
                md: '200px',
                xl: '240px',
              })}
            />
          </Box>
        </Box>
        <Box
          bg={useBreakpointValue({
            base: 'none',
            sm: 'none',
            md: 'none',
            xl: 'linear-gradient(to right bottom,rgba(238,244,255,1) 0%, rgba(233,255,247,1) 50%, rgba(249,237,255,1) 100%)',
          })}
          ref={fourthSectionRef}
          p={useBreakpointValue({
            base: '0 16px',
            sm: '0 16px ',
            md: '0 32px',
            // lg: '62em',
            xl: '0 320px',
          })}
        >
          <Box pt={SectionPt2}>
            <TextArea
              title={<>Image generation using AI</>}
              content={
                <>
                  XROOM has built-in image generation AI to give users a
                  creative edge. With this feature, users can utilize AI within
                  XROOM to launch creative projects and build a better
                  collaborative experience.
                </>
              }
            />
            <Img
              src={useBreakpointValue({
                base: '/images/xroom_wis/mobile2.png',
                sm: '/images/xroom_wis/mobile2.png',
                md: '/images/xroom_wis/aiImg.jpg',
                xl: '/images/xroom_wis/aiImg.jpg',
              })}
              alt="rectangle"
              // width={203}
              // height={VideoHeight}
              marginTop={useBreakpointValue({
                base: '32px',
                sm: '32px',
                md: '52px',
                xl: '48px',
              })}
              marginBottom={useBreakpointValue({
                base: '120px',
                sm: '120px',
                md: '200px',
                xl: '240px',
              })}
            />
          </Box>
        </Box>
        <Box
          ref={fifthSectionRef}
          p={useBreakpointValue({
            base: '0 16px',
            sm: '0 16px',
            md: '0 32px',
            xl: '0 320px',
          })}
        >
          <Box
            p={useBreakpointValue({
              base: '0 0px 120px 0px',
              sm: '0 0px 120px 0px',
              md: '0 0px 200px 0px',
              xl: '170px 0 240px',
            })}
          >
            <TextArea
              title={<>Virtual avatars for showcasing</>}
              content={
                <>
                  Users can unleash their creativity and imagination by creating
                  their own projects with colorful 3D assets and effects. Edit
                  spaces, create new worlds, and start branding.
                </>
              }
            />
            <Img
              src={useBreakpointValue({
                base: '/images/xroom_wis/mobile3.png',
                sm: '/images/xroom_wis/mobile3.png',
                md: '/images/xroom_wis/groupImg2.png',
                xl: '/images/xroom_wis/groupImg2.png',
              })}
              alt="groupImg"
              marginTop={useBreakpointValue({
                base: '32px',
                sm: '32px',
                md: '52px',
                xl: '48px',
              })}
            />
          </Box>
        </Box>
        <Box ref={sixthSectionRef}>
          <Section>
            <TextArea
              title={useBreakpointValue({
                base: <>From simple shorts to professional productions</>,
                xl: <>From simple shorts to professional productions</>,
                default: 'From simple shorts to professional productions',
              })}
              content={
                <>
                  You can shoot anywhere, from your home to a large filming
                  studio, and SoulX offers professional production facilities
                  such as virtual studio, 360-degree studio, and multi-purpose
                  studio with the XRoom software solution.
                </>
              }
            />
          </Section>
          <Box
            p={useBreakpointValue({
              base: '0 16px',
              sm: '0 16px',
              md: '0 32px',
              xl: '0',
            })}
          >
            <Img
              src={useBreakpointValue({
                base: '/images/xroom_wis/mobileFrameImg.png',
                sm: '/images/xroom_wis/mobileFrameImg.png',
                md: '/images/xroom_wis/mobileFrameImg.png',
                xl: '/images/xroom_wis/frame.png',
              })}
              alt="frame"
              // width={203}
              // height={VideoHeight}
              marginTop={useBreakpointValue({
                base: '32px',
                sm: '32px',
                md: '52px',
                xl: '48px',
              })}
            />
          </Box>
        </Box>
        {/* section7 */}
        <Box
          ref={seventhSectionRef}
          p={useBreakpointValue({
            base: '120px 0',
            sm: '120px 0',
            md: '200px 32px',
            xl: '280px 320px 190px',
          })}
          textAlign={useBreakpointValue({
            base: 'left',
            sm: 'left',
            md: 'left',
            xl: 'center',
          })}
        >
          <Box
            p={useBreakpointValue({
              base: '0 16px',
              sm: '0 16px',
              md: '0',
              xl: '0',
            })}
          >
            <Text
              fontSize={useBreakpointValue({
                base: '24px',
                sm: '24px ',
                md: '48px',
                xl: '48px',
              })}
              fontWeight="bold"
              lineHeight={useBreakpointValue({
                base: '32px',
                sm: '32px ',
                md: 'auto',
                xl: '60px',
              })}
            >
              A vibrant XR production <br />
              experience for everyone
            </Text>
            <Text
              fontSize={useBreakpointValue({
                base: '15px',
                sm: '15px ',
                md: '20px',
                xl: '20px',
              })}
              pt={'20px'}
              color={'#6B6C74'}
              fontWeight={'medium'}
            >
              XRoom offers three pricing plans:
            </Text>
            <Button
              backgroundColor="black"
              color="white"
              _hover={{ backgroundColor: 'gray.800' }} // hover 시 색 변경
              _active={{ backgroundColor: 'gray.900' }} // 클릭 시 색 변경
              p={'9px 22px'}
              borderRadius={'4px'}
              mt={useBreakpointValue({
                base: '20px',
                sm: '20px',
                md: '32px',
                xl: '20px',
              })}
              onClick={() => {
                window.open(
                  'https://docs.google.com/forms/d/e/1FAIpQLSeGqhJwxOVZaxUx4-iH0LbGmNTpqHCer8-4ZhWuJlQypdg9qw/viewform',
                );
              }}
              fontWeight={'medium'}
              fontSize={'16px'}
            >
              Ask about prices
            </Button>
          </Box>
          <Box
            p={useBreakpointValue({
              base: '0 16px',
              sm: '0 16px',
              md: '0',
              xl: '0',
            })}
          >
            <Text
              fontSize={'12px'}
              pt={useBreakpointValue({
                base: '48px',
                sm: '48px',
                md: '0',
                xl: '0',
              })}
              color={'#83838C'}
              textAlign={'left'}
              display={useBreakpointValue({
                base: 'block',
                sm: 'block',
                md: 'none',
                xl: 'none',
              })}
            >
              *XROOM is a system that remains active for three years with a
              one-time purchase
            </Text>
            <Box
              overflowX={'scroll'}
              css={{
                '&::-webkit-scrollbar': {
                  display: 'none',
                  scrollbarWidth: 'none',
                },
                scrollbarWidth: 'none',
              }}
            >
              <Img
                src={useBreakpointValue({
                  base: '/images/xroom_wis/mobileList_en2.png',
                  sm: '/images/xroom_wis/mobileList_en2.png',
                  md: '/images/xroom_wis/list_en.png',
                  xl: '/images/xroom_wis/list_en.png',
                })}
                alt="frame"
                marginTop={useBreakpointValue({
                  base: '20px',
                  sm: '20px',
                  md: '40px',
                  xl: '60px',
                })}
                maxWidth={useBreakpointValue({
                  base: '155%',
                  sm: '155%',
                  md: '100%',
                  xl: '100%',
                })}
              />
            </Box>
            <Text
              fontSize={'16px'}
              pt={'20px'}
              color={'#83838C'}
              textAlign={useBreakpointValue({
                base: 'left',
                sm: 'left',
                md: 'right',
                xl: 'right',
              })}
              display={useBreakpointValue({
                base: 'none',
                sm: 'none',
                md: 'block',
                xl: 'block',
              })}
            >
              *XROOM is a system that remains active for three years with a
              one-time purchase
            </Text>
          </Box>
        </Box>

        <Box
          ref={eighthSectionRef}
          backgroundImage="url('/images/xroom_wis/footerBg.png')"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          height={'100vh'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          p={useBreakpointValue({
            base: '0 22px',
            sm: '0 22px',
            md: '0 32px',
            xl: '0',
          })}
        >
          <Img
            src="/images/xroom_wis/logo.png"
            alt="frame"
            width={useBreakpointValue({
              base: '162px',
              sm: '162px',
              md: '335px',
              xl: '404px',
            })}
          />
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <Text
              fontSize={useBreakpointValue({
                base: '20px',
                sm: '20px',
                md: '40px',
                xl: '56px',
              })}
              fontWeight="medium"
              mt={useBreakpointValue({
                base: '20px',
                sm: '20px',
                md: '40px',
                xl: '40px',
              })}
            >
              Are you ready to explore XROOM?
            </Text>
            <Text
              mt={useBreakpointValue({
                base: '8px',
                sm: '8px',
                md: '20px',
                xl: '20px',
              })}
              fontSize={useBreakpointValue({
                base: '16px',
                sm: '16px',
                md: '20px',
                xl: '20px',
              })}
              textAlign={'center'}
              lineHeight={useBreakpointValue({
                base: 'auto',
                sm: 'auto',
                md: '30px',
                xl: '20px',
              })}
            >
              Craft your vivid world with XR content creation. Shape leaders for
              the new media era with XRoom.
            </Text>
            <Button
              background={'#000'}
              size="md"
              borderRadius="4px"
              color={'#fff'}
              fontWeight="medium"
              fontSize={'20px'}
              mt={useBreakpointValue({
                base: '16px',
                sm: '16px',
                md: '20px',
                xl: '20px',
              })}
              _hover={{ background: '#444' }}
              onClick={() => {
                window.open(
                  ' https://store.steampowered.com/app/2616930/XROOMLite/',
                );
              }}
            >
              Download the XROOM
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default XroomWISDesktop;
