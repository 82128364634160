import React from 'react';
import { Fade } from 'react-awesome-reveal';
import awards_db from 'db/awards.json';

const Awards: React.FC = () => {
  return (
    <>
      <section className="awards" id="awards">
        <Fade direction="left" triggerOnce>
          <div className="container px-xxl-6 text-start">
            <div className="row gx-5 align-items-center justify-content-center">
              <div className="col-lg-6 col-xl-6 col-xxl-7-1 align-self-start">
                <div className="my-5 text-xl-start">
                  <div className="text-white fw-bolder mb-5-2 fs-4-2 fs-h-s-3">
                    인증 및 수상
                  </div>
                  <ul>
                    <li className="nav-item">
                      <div className="text-white fw-light fs-h-l-0-2 fs-h-s-1 mb-4">
                        벤처기업 등록, 직무발명보상 우수기업 인증,
                        기업부설연구소 설립
                      </div>
                    </li>
                    <li className="nav-item">
                      <div className="text-white fw-light fs-h-l-0-2 fs-h-s-1 mb-4 lh-lg word-keep">
                        지식재산권 보유: ‘3D가상공간에 실시간 영상을 합성하는
                        방법 및 그를 이용한 컴퓨팅 장치’에 관한 기술특허 등록
                        (2020.12.11) (등록번호: 10-2192412)
                      </div>
                    </li>
                    <li className="nav-item">
                      <div className="text-white fw-light fs-h-l-0-2 fs-h-s-1">
                        대한민국 청년 스타트업 네트워크 &lt;2020
                        스타트업X스타트업 프로젝트&gt; 대상 수상
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 col-xxl-4-1 awards-imgs">
                <div className="col-5-1 mb-4 mx-2">
                  <div className="portfolio-item">
                    <img
                      className="img-fluid"
                      src={awards_db.imagePaths[0]}
                      alt="..."
                    />
                  </div>
                </div>
                <div className="col-5-1 mb-4 mx-2">
                  <div className="portfolio-item">
                    <img
                      className="img-fluid"
                      src={awards_db.imagePaths[1]}
                      alt="..."
                    />
                  </div>
                </div>
                <div className="col-5-1 mb-4 mx-2">
                  <div className="portfolio-item">
                    <img
                      className="img-fluid"
                      src={awards_db.imagePaths[2]}
                      alt="..."
                    />
                  </div>
                </div>
                <div className="col-5-1 mb-4 mx-2">
                  <div className="portfolio-item">
                    <img
                      className="img-fluid"
                      src={awards_db.imagePaths[3]}
                      alt="..."
                    />
                  </div>
                </div>
                <div className="col-5-1 mb-4 mx-2">
                  <div className="portfolio-item">
                    <img
                      className="img-fluid"
                      src={awards_db.imagePaths[4]}
                      alt="..."
                    />
                  </div>
                </div>
                <div className="col-5-1 mb-4 mx-2">
                  <div className="portfolio-item">
                    <img
                      className="img-fluid"
                      src={awards_db.imagePaths[5]}
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    </>
  );
};

export default Awards;
