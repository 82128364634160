import React from 'react';
import { Fade } from 'react-awesome-reveal';
import Portfolio from './portfolio';
import works from 'db/works.json';
import { Link } from 'react-router-dom';

const Portfolios: React.FC = () => {
  const workImageList: JSX.Element[] = works.main.map((item, idx) => (
    <Portfolio
      imagePath={item.imagePath}
      title={item.title}
      subtitle={item.subtitle}
      key={idx}
    ></Portfolio>
  ));

  return (
    <>
      <section className="portfolio" id="portfolio">
        <Fade direction="left" className="h-100" triggerOnce>
          <div className="container px-4 px-xl-6 h-100" id="workscontainer">
            <div className="row gx-4 gx-lg-5 h-100 text-start">
              <div className="col-lg-8 align-self-end w-75 col-xl-7 col-xxl-7-1">
                <div
                  className="text-white fw-bolder mb-h-l-5-2 mb-h-s-1 fs-1"
                  id="animation-works-target"
                >
                  Portfolio
                </div>
              </div>
              <div className="col-12 align-self-baseline">
                <div className="text-white fw-light fs-h-l-1 fs-h-s-2 lh-lg word-keep lh-2 w-lg-50">
                  소울엑스는 문화, 예술, 교육, 부동산, 건축, AI(인공지능),
                  자율주행, VR, AR, 게임 등 다양한 분야에 걸쳐 3D 가상공간과
                  오브젝트를 제작하는 프로젝트를 수행했습니다. 국내 및 해외
                  기업과의 협업은 물론 공공기관과의 협약을 통한 연구 개발
                  프로젝트를 다수 진행했습니다.
                </div>
                <div className="text-white fw-extralight fs-h-l-1 fs-h-s-2 lh-lg word-keep lh-2 w-lg-50">
                  SOULX has carried out projects to create 3D virtual spaces and
                  objects in various fields including culture, art, education,
                  real estate, architecture, AI, autonomous driving, VR, AR, and
                  games. We have carried out a number of research and
                  development projects through cooperation with domestic and
                  overseas companies, as well as with public institutions.
                </div>
              </div>
              {workImageList}
              <div className="col-lg-8 w-lg-50 col-xl-7 col-xxl-7-1 w-sm-100 mb-4">
                <Link
                  to="/portfolio"
                  className="btn btn-primary btn-xl mb-hsm-1 mt-hsm-1"
                >
                  Read More &gt;
                </Link>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    </>
  );
};

export default Portfolios;
